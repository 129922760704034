import { useLocation } from '@reach/router';
import apiQuota from 'api/order-quota';
import apiUniqueIdValidator from 'api/unique-id-validator';
import PersonalInformationForm from 'components/forms/PersonalInformationForm';
import dayjs from 'dayjs';
import { navigate } from 'gatsby';
import React from 'react';
// eslint-disable-next-line import-helpers/order-imports
import { ERROR_TITLE, ERROR_CONTENT } from 'utils/constants';

// local imports
import {
  analytics,
  displayError,
  isValidDate,
  isValidState,
  getShippableStates,
  formatDateOfBirth,
} from 'utils/helpers';
import { getUser } from 'utils/services/auth';
import { setInnerNavigate } from 'utils/services/auth';

import TestTakerPaymentWrapper from '../index';
import * as S from './styles';

interface LocationState {
  productIds: number[];
  disabledStates: string[];
  tenantName: string;
  uniqueIdValidationEnabled: boolean;
  productIdsToMinimumAge: { [productId: number]: number; };
  dob: string;
  email?: string;
  firstName: string;
  lastName: string;
  thirdPartyId: string;
  state: string;
  confirmThirdPartyId: string;
  uniqueId: {
    required?: boolean;
    tooltip?: string;
  };
}

interface FormData {
  lastName: string;
  dob: string;
  thirdPartyId: string;
  email: string;
}

const PersonalInformationWrapper = () => {
  const location = useLocation();
  const locationState = location?.state as LocationState;

  const uniqueId = locationState?.uniqueId || {};
  const productIds = locationState.productIds || [];
  const disabledStates = locationState.disabledStates || [];
  const tenantName = locationState?.tenantName || '';
  const uniqueIdValidationEnabled =
    locationState?.uniqueIdValidationEnabled || false;

  const productIdsToMinimumAge =
    locationState.productIdsToMinimumAge || {};
  const minimumAgeConstraints = productIds.map((productId) => {
    return productIdsToMinimumAge[productId];
  });

  const validateUniqueId = async (data: { lastName: string; birthday: string; userId: string; tenantName: string; }) => {
    try {
      const response = await apiUniqueIdValidator(
        {
          member_id: data.userId,
          dob: formatDateOfBirth(data.birthday),
          last_name: data.lastName,
        },
        data.tenantName,
      );

      return response.data;
    } catch (error) {
      return { statusCode: '500', error };
    }
  };

  const onSubmit = async (formData: FormData, setLoading: React.Dispatch<React.SetStateAction<boolean>>) => {
    setInnerNavigate();
    let newState = {};

    if (uniqueId.required && uniqueIdValidationEnabled) {
      const dataForValidation = {
        lastName: formData.lastName,
        birthday: formData.dob,
        userId: formData.thirdPartyId,
        tenantName: tenantName,
      };

      const response = await validateUniqueId(dataForValidation);

      if (response.statusCode !== '200') {
        setLoading(false);

        analytics.track('CTA Clicked', {
          error_type: ERROR_TITLE.INVALID_UNIQUE_ID,
          text: ERROR_CONTENT.INVALID_UNIQUE_ID.replace(/(<([^>]+)>)/gi, ''),
        });

        return displayError(
          ERROR_TITLE.INVALID_UNIQUE_ID,
          ERROR_CONTENT.INVALID_UNIQUE_ID,
        );
      }
    }

    apiQuota({
      email: formData.email,
      uniqueId: formData.thirdPartyId,
      accessCode: getUser().accessCode,
      enterprisePartnerId: process.env.GATSBY_ENTERPRISE_PARTNER_ID,
    })
      .then((response) => {
        const data = response.data;
        const { kitSelectionEnabled, allowedQuantityPerProduct } = data;

        analytics.track('CTA Clicked', {
          text: 'Verified Personal Information Successfully',
        });

        newState = {
          isQuotaFull: false,
          kitSelectionEnabled: kitSelectionEnabled,
          allowedQuantityPerProduct: kitSelectionEnabled
            ? allowedQuantityPerProduct
            : null,
        };
      })
      .catch(() => {
        newState = { isQuotaFull: true };
      })
      .finally(() => {
        navigate('/app/payment-steps/available-tests', {
          state: Object.assign({}, locationState, formData, newState),
        });
      });
  };

  const onCancel = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();

    setInnerNavigate();

    analytics.track('CTA Clicked', {
      text: 'Cancel',
      type: 'Button',
    });

    navigate('/');
  };

  const validateDate = (value: string) => {
    if (!isValidDate(value)) return false;

    // eslint-disable-next-line array-callback-return
    const errors = minimumAgeConstraints.map((ageConstraint) => {
      if (dayjs().diff(value, 'year') < ageConstraint)
        return `You need to be at least ${ageConstraint} years old to purchase this kit.`;
    });

    return errors[0] ? errors[0] : true;
  };

  const validateState = (value: string) => {
    return isValidState(value, disabledStates);
  };

  return (
    <TestTakerPaymentWrapper
      title="Personal Information"
      showOrderSummary={true}
    >
      <S.Container>
        <PersonalInformationForm
          uniqueId={uniqueId}
          onSubmit={onSubmit}
          onCancel={onCancel}
          customDateValidate={validateDate}
          isValidState={validateState}
          dob={locationState?.dob}
          email={locationState?.email}
          firstName={locationState?.firstName}
          lastName={locationState?.lastName}
          thirdPartyId={locationState?.thirdPartyId}
          state={locationState?.state}
          uniqueIdTooltip={uniqueId?.tooltip}
          shippableStates={getShippableStates(disabledStates)}
          confirmThirdPartyId={locationState?.confirmThirdPartyId}
        />
      </S.Container>
    </TestTakerPaymentWrapper>
  );
};

export default PersonalInformationWrapper;
