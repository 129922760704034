import React, { ChangeEvent, useState } from 'react';

// local imports
import { useLineItems } from 'hooks';
import { DropdownOptionType } from '@everlywell/leaves';

import * as S from './styles';

interface IQuantityDropdown {
  productId: number;
  options: DropdownOptionType[];
  source: string;
}

const QuantityDropdown = ({ productId, options, source }: IQuantityDropdown) => {
  const { lineItems, updateLineItem } = useLineItems(source);

  const findProduct = () => {
    return lineItems?.find((item) => item.product_id === productId);
  };

  const [value, setValue] = useState(() => {
    const foundProduct = findProduct();

    if (!foundProduct) return 1;

    return foundProduct.quantity;
  });

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const quantity = Number(event.currentTarget.value);

    setValue(quantity);
    updateLineItem(quantity, productId);
  };

  return (
    <S.QuantityDropdown
      id={`quantityDropdown-${productId}`}
      items={options}
      label="Quantity:"
      name="quantity"
      onChange={handleChange}
      value={String(value)}
    />
  );
};

export default QuantityDropdown;
