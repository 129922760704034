import { useLocation } from '@reach/router';
import ShippingInfoForm from 'components/forms/ShippingInfoForm';
import { Divider } from 'components/ui';
import { WizardContext } from 'contexts/wizard';
import React, { useContext, useEffect } from 'react';
import { useLineItems } from 'hooks';
import { MainContainerWrapper } from 'styles/global';
import { OVERNIGHT_SHIPPING } from 'utils/constants';
import {
  analytics,
  normalizePhone,
  getShippableStates,
  deliveryOptionFormat,
} from 'utils/helpers';

import { PAGE_INFO } from '../constants';
import * as S from './styles';
import { IForm } from 'components/forms/ShippingInfoForm/use-form';
import { State as FormState } from 'components/forms/ShippingInfoForm/use-form/helpers';

interface LocationState {
  disabledStates?: string[];
}

interface Props {
  moveForward: () => void;
}

const ShipmentStep = ({ moveForward }: Props) => {
  const {
    name,
    address1,
    address2,
    city,
    state,
    zipcode,
    email,
    phone,
    shippingId,
    deliveryOptions,
    configValues,
    setWizard,
  } = useContext(WizardContext);

  const [firstName, lastName] = name.split(/\s(.*)/);

  const eventData = {
    page_name: PAGE_INFO[2].name,
  };

  const location = useLocation();
  const locationState = location.state as LocationState;
  const disabledStates = locationState?.disabledStates || [];

  const { shouldDisableOvernightShipping } = useLineItems('enterprise');

  const formattedDeliveryOptions = deliveryOptions
    .filter((option) => {
      if (
        shouldDisableOvernightShipping() &&
        option.id === OVERNIGHT_SHIPPING
      ) {
        return false;
      }

      return true;
    })
    .map(deliveryOptionFormat);

  const onSubmitError = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const onSubmit = (data: IForm, errors: FormState['errors']) => {
    const {
      address1,
      address2,
      city,
      state,
      zipcode,
      phoneNumber,
      shippingId,
    } = data;

    if (Object.keys(errors).length > 0) {
      return onSubmitError();
    }

    setWizard({
      address1,
      address2,
      city,
      state,
      zipcode,
      shippingId: Number(shippingId),
      phone: phoneNumber,
    });

    analytics.track('CTA Clicked', {
      ...eventData,
      text: 'Successfully provided Shipping Information',
    });

    moveForward();
  };

  useEffect(() => {
    analytics.track('Page Viewed', eventData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MainContainerWrapper>
      <S.PageTitle>Shipping Information</S.PageTitle>
      <S.Subtitle>Tell us where you'd like us to send this test to.</S.Subtitle>

      <ShippingInfoForm
        initialValues={{
          firstName,
          lastName,
          address1,
          address2,
          city,
          state,
          zipcode,
          email,
          phoneNumber: normalizePhone(phone),
          shippingId: shippingId ?? 7,
        }}
        disableNameFields
        configValues={configValues}
        disableContactFields
        shippableStates={getShippableStates(disabledStates)}
        buttonProps={{
          hasArrow: true,
          style: {
            minWidth: 'auto',
            marginLeft: 'auto',
          },
        }}
        onSubmit={onSubmit}
        deliveryOptions={formattedDeliveryOptions}
      />
      <Divider isHidden spacing="16px" />
    </MainContainerWrapper>
  );
};

export default ShipmentStep;
