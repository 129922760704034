import { camelCase } from 'camel-case';
import { Stripe, StripeElements } from '@stripe/stripe-js';

/**
 *  Creates Stripe Token from Stripe Card Element
 *
 * @param stripe the Stripe object from useStripe
 * @param elements the Stripe Elements Object from useElements
 * @returns the preauthorized Stripe Token that we will pass to the backend
 */
export const getStripeToken = async (stripe: Stripe, elements: StripeElements) => {
  const cardElement = elements.getElement('cardNumber');
  if (cardElement) {
    try {
      const { token, error } = await stripe.createToken(cardElement);
      return token
        ? token
        : Promise.reject(
          error?.message ||
          'Failed to generate token for element `cardElement`  (bad response)',
        );
    } catch (err) {
      return Promise.reject(
        'Failed to generate token for element `cardElement`',
      );
    }
  } else {
    return Promise.reject('Failed to locate element `cardElement`');
  }
};

/**
 * Appropriately formatted Stripe payment information for what the Spree
 * endpoint expects
 *
 * @param token the stripe token returned from the API
 * @returns the necessary payment details for a Stripe purchase
 */
export const getStripePaymentDetails = async (stripe: Stripe, elements: StripeElements) => {
  if (stripe && elements) {
    try {
      const {
        id,
        card,
      } = await getStripeToken(stripe, elements);

      return Promise.resolve({
        stripeToken: id,
        brand: camelCase(card?.brand!),
        last4: card?.last4,
      });
    } catch (e) {
      return Promise.reject(e);
    }
  } else {
    return Promise.reject('Stripe is not initialized');
  }
};
