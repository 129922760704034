import { useLocation } from '@reach/router';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
// local imports
import { TestTakerPaymentContext } from 'contexts/test-taker-payment';
import { navigate } from 'gatsby';
import React, { FormEvent, useContext } from 'react';
import { analytics } from 'utils/helpers';
import { setInnerNavigate } from 'utils/services/auth';

import TestTakerPaymentWrapper from '../index';
import ShippingPaymentInformation from './ShippingPaymentInformation';

import { ShippingFields } from './ShippingPaymentInformation';

interface FormData {
  cardNumber?: string;
  last4?: string;
  shippingAddress: ShippingFields;
  billingAddress: ShippingFields;
  marketingAgreement?: boolean;
  paymentProvider?: string;
  stripeMethodName?: string;
  paypalPayerId?: string;
  paypalPaymentId?: string;
  stripeToken?: string;
}

interface LocationState {
  products: Product[];
  spreeOrder: TestTakerOrder;
  productIds: { [productId: string]: number; }[];
  contentfulProductIds: { [productId: string]: number; }[];
  shippingMethods: ShippingMethod[];
  enterpriseClientId: number | null;
  uniqueId: {
    required: boolean;
    format: string;
    label?: string;
    tooltip?: string;
  };
  disabledStates: string[];
  productIdsToMinimumAge: { [productId: string]: number | null; },
  lineItems: LineItem[];
  tenantName?: string;
  uniqueIdValidationEnabled: boolean;
  autoRegisterOrders: boolean;
  key: string;
  firstName: string;
  lastName: string;
  email: string;
  dob: string;
  state: string;
  thirdPartyId: string;
  confirmThirdPartyId: string;
  isQuotaFull: boolean;
  kitSelectionEnabled: boolean;
  allowedQuantityPerProduct: { [productId: string]: number; } | null;
}

const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBLIC_KEY!);

const PaymentWrapper = () => {
  const location = useLocation();
  const locationState = location.state as LocationState;
  const { selectedShippingMethod } = useContext(TestTakerPaymentContext);

  const onSubmit = (formData: FormData) => {
    setInnerNavigate();
    navigate('/app/payment-steps/review-order', {
      state: Object.assign(locationState, {
        cardNumber: formData?.cardNumber || formData?.last4,
        shippingMethodId: selectedShippingMethod.id,
        shippingAddress: formData.shippingAddress,
        billingAddress: formData.billingAddress,
        marketingAgreement: formData.marketingAgreement,
        paymentProvider: formData.paymentProvider,
        stripeMethodName: formData.stripeMethodName,
        paypalParams: {
          payer_id: formData.paypalPayerId,
          payment_id: formData.paypalPaymentId,
        },
        stripeParams: {
          stripe_token: formData.stripeToken,
        },
      }),
    });
  };

  const onCancel = (event: FormEvent) => {
    event.preventDefault();
    setInnerNavigate();

    analytics.track('CTA Clicked', {
      text: 'Cancel',
      type: 'Button',
    });

    navigate('/');
  };

  return (
    <TestTakerPaymentWrapper
      title="Shipping & Payment Information"
      showOrderSummary={true}
    >
      <Elements stripe={stripePromise}>
        <ShippingPaymentInformation
          order={locationState.spreeOrder}
          onSubmit={onSubmit}
          onCancel={onCancel}
        />
      </Elements>
    </TestTakerPaymentWrapper>
  );
};

export default PaymentWrapper;
