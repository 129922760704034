import { Checkbox, Row } from '@everlywell/leaves';
import { useLocation } from '@reach/router';
import { camelCase } from 'camel-case';
import BillingAddressForm from 'components/forms/BillingAddressForm';
import DeliveryOptionsSelector from 'components/forms/DeliveryOptionsSelector';
import TestTakerAddressForm from 'components/forms/TestTakerAddressForm';
import { TestTakerPaymentContext } from 'contexts/test-taker-payment';
import React, { useState, useContext, useRef, FormEvent } from 'react';
import { useForm, UseFormMethods } from 'react-hook-form';
import useLineItems from 'hooks/use-line-items';
import { StripePaymentButtonProps } from 'components/access-code/pages/test-taker-payment/checkout/StripePaymentButtons';

import {
  analytics,
  displayError,
  shippingOptionFormat,
  normalizePhone,
  isValidState,
} from 'utils/helpers';

import PaypalButton from '../../checkout/PaypalButton';
import StripeFields from '../../checkout/StripeFields';
import StripePaymentButtons from '../../checkout/StripePaymentButtons';
import AcceptedPayments from './AcceptedPayments';
import * as S from './styles';

export interface ShippingFields {
  city: string;
  email?: string;
  state: string;
  zipCode: string;
  address: string;
  lastName: string;
  firstName: string;
  phoneNumber?: string;
}

interface ButtonGroupProps {
  isLoading: boolean;
  onCancel: (event: FormEvent) => void;
  watchFields: {
    shippingAddress: ShippingFields;
    billingAddress: ShippingFields;
  };
  isCreditCardPayment: boolean;
  isValidCreditCard: boolean;
}

interface Props {
  billingAddress?: Partial<ShippingFields>;
  order: TestTakerOrder;
  onSubmit: (data: ButtonGroupProps['watchFields']) => void;
  onCancel: (event: FormEvent) => void;
}

interface LocationState {
  shippingAddress: ShippingFields;
  disabledStates: string[];
  email: string;
  firstName: string;
  lastName: string;
  state: string;
  shippingMethods: DeliveryOption[]
}

const ButtonGroup = ({
  isLoading,
  onCancel,
  watchFields,
  isCreditCardPayment,
  isValidCreditCard,
}: ButtonGroupProps) => {
  const shippingAddressIsFilled = () => {
    return (
      watchFields.shippingAddress.city &&
      watchFields.shippingAddress.email &&
      watchFields.shippingAddress.state &&
      watchFields.shippingAddress.zipCode &&
      watchFields.shippingAddress.address &&
      watchFields.shippingAddress.lastName &&
      watchFields.shippingAddress.firstName
    );
  };

  const billingAddressIsFilled = () => {
    if (!watchFields.hasOwnProperty('billingAddress')) return true;
    return (
      watchFields.billingAddress.city &&
      watchFields.billingAddress.state &&
      watchFields.billingAddress.zipCode &&
      watchFields.billingAddress.address &&
      watchFields.billingAddress.lastName &&
      watchFields.billingAddress.firstName
    );
  };

  const creditCardInfoIsFilled = () => {
    if (!isCreditCardPayment) return true;
    return isValidCreditCard;
  };

  const buttonEnabled =
    shippingAddressIsFilled() &&
    billingAddressIsFilled() &&
    creditCardInfoIsFilled();

  return (
    <S.ButtonGroup reverse={true}>
      <S.PrimaryButton
        appearance="primary"
        isLoading={isLoading}
        hasArrow={!isLoading}
        isDisabled={!buttonEnabled}
      >
        Continue
      </S.PrimaryButton>
      <S.SecondaryButton onClick={onCancel} appearance="secondary">
        Cancel
      </S.SecondaryButton>
    </S.ButtonGroup>
  );
};

const MarketingAgreement = ({ register, setValue }: { register: UseFormMethods['register'], setValue: UseFormMethods['setValue'] }) => {
  const [checked, setChecked] = useState(false);

  const handleChange = () => {
    setValue('marketingAgreement', !checked);
    setChecked(!checked);
  };

  return (
    <Checkbox
      bodyTextStyle
      ref={register()}
      checked={checked}
      onChange={handleChange}
      name="marketingAgreement"
      label="I agree to customized suggestions and content for Everlywell marketing purposes. We will never spam you!"
    />
  );
};

const ShippingPaymentInformation = ({
  billingAddress = {},
  order = {} as TestTakerOrder,
  onSubmit,
  onCancel,
}: Props) => {
  const location = useLocation();
  const locationState = location?.state as LocationState;

  const disabledStates = locationState.disabledStates || [];
  const { updateShippingMethod } = useContext(TestTakerPaymentContext);
  const { shouldDisableOvernightShipping } = useLineItems();

  const eventData = {
    page_name: 'Test Taker Paid - Shipping and Payment',
  };

  const defaultValues = {
    shippingAddress: {
      email: locationState?.email,
      firstName: locationState?.firstName,
      lastName: locationState?.lastName,
      address: locationState?.shippingAddress?.address,
      city: locationState?.shippingAddress?.city,
      state: locationState?.state,
      zipCode: locationState?.shippingAddress?.zipCode,
      phoneNumber: locationState?.shippingAddress?.phoneNumber,
    },
    billingAddress: {
      email: locationState?.email,
      firstName: locationState?.firstName,
      lastName: locationState?.lastName,
      address: '',
      city: '',
      state: locationState?.state,
      zipCode: '',
      phoneNumber: '',
    },
    paymentProvider: '',
    stripeMethodName: '',
    stripeToken: '',
    last4: '',
    paypalPayerId: '',
    paypalPaymentId: '',
  };

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
    watch,
  } = useForm({
    reValidateMode: 'onBlur',
    defaultValues,
  });

  const watchFields = watch();

  const [isLoading, setIsLoading] = useState(false);

  const [cardExpiryValid, setCardExpiryValid] = useState(false);
  const [cardNumberValid, setCardNumberValid] = useState(false);
  const [cardCvcValid, setCardCvcValid] = useState(false);

  const [isCreditCardPayment, setCreditCardPayment] = useState(true);

  const stripeFields = useRef<{ getStripeDetails: () => { last4: string; brand: string; stripeToken: string; }; }>();

  const submitCallback = async () => {
    setIsLoading(true);

    analytics.track('CTA Clicked', {
      ...eventData,
      text: 'Successfully provided Shipping and Payment Information',
    });

    if (isCreditCardPayment) {
      // After setting the Stripe Details we have to then retrieve
      // the values using react-hook-form's getValues method since
      // watch method cannot detect changes to the input since they are
      // iframed in

      await setStripeDetails();
      const stripeValues = getValues([
        'stripeToken',
        'paymentProvider',
        'last4',
      ]);
      onSubmit({ ...watchFields, ...stripeValues });
    } else {
      onSubmit(watchFields);
    }
  };

  // setStripeDetails gets the stripe_token from stripe and
  // sets the stripeToken, paymentProvider/cardBrand, and the last 4 digits
  // of the card number

  const setStripeDetails = async () => {
    try {
      const { stripeToken, brand, last4 } = await stripeFields?.current?.getStripeDetails() ?? {};

      setValue('stripeToken', stripeToken);
      setValue('paymentProvider', brand);
      setValue('last4', last4);
    } catch (e) {
      // eslint-disable-next-line no-console
      return console.error(e);
    }
  };

  const handlePaymentRequestConfirm: StripePaymentButtonProps['handlePaymentConfirm'] = (data, token, complete) => {
    const { payerEmail, shippingAddress, payerName, payerPhone } = data;
    const { addressLine, city, postalCode, region } = shippingAddress ?? {};
    const [firstName, lastName] = payerName?.split(' ') ?? [];

    // Fill form with data set by Payment Request API (e.g. ApplePay, GooglePay, ExpressPay)
    setValue('shippingAddress[email]', payerEmail);
    setValue('shippingAddress[firstName]', firstName);
    setValue('shippingAddress[lastName]', lastName);
    setValue('shippingAddress[phoneNumber]', normalizePhone(payerPhone ?? ''));
    setValue('shippingAddress[city]', city);
    setValue('shippingAddress[state]', region);
    setValue('shippingAddress[zipCode]', postalCode);
    setValue('shippingAddress[address]', addressLine);

    // Update Stripe Token
    setValue('stripeToken', token.id);
    setValue('stripeMethodName', data.methodName);

    // Update Last 4 Digits & Payment Provider
    setValue('last4', token?.card?.last4);
    setValue('paymentProvider', camelCase(token?.card?.brand ?? ''));

    // Hide the credit card fieldset
    setCreditCardPayment(false);

    // Complete the Stripe Payment Request
    complete('success');
  };

  // When the shipping option changes in the Payment Request UI
  // we should reflect that update in the form as well as update the
  // price in both the Payment Request UI and our UI.

  const handlePaymentShippingChange: StripePaymentButtonProps['handleShippingOptionChange'] = (update, shippingOption) => {
    const amount = +order.total.replace(/\./g, '');
    setValue('shippingType', shippingOption.id);
    update({
      status: 'success',
      total: {
        amount: amount + shippingOption.amount,
        label: shippingOption.label,
        pending: true,
      },
    });
  };

  const onCardNumberValid = (value: boolean) => setCardNumberValid(value);
  const onCardExpiryValid = (value: boolean) => setCardExpiryValid(value);
  const onCardCvcValid = (value: boolean) => setCardCvcValid(value);

  const onPayPalSuccess = (_: unknown, data: { payer_id: string; payment_id: string; }) => {
    setValue('paypalPayerId', data.payer_id);
    setValue('paypalPaymentId', data.payment_id);
    setValue('paymentProvider', 'payPal');
    setCreditCardPayment(false);
  };

  const validateState = (state: string) => isValidState(state, disabledStates);

  const onPayPalError = () => {
    displayError('There was an issue with Paypal checkout. Please try again.');
  };

  const formattedShippingOptions = locationState.shippingMethods.map(
    (option) => shippingOptionFormat(option),
  );

  const handleShippingChange = (shippingMethod: ShippingMethod) => {
    order.shipping = shippingMethod.price;
    updateShippingMethod(shippingMethod);
  };

  return (
    <S.ShippingPaymentContainer>
      <form onSubmit={handleSubmit(submitCallback)}>
        <S.ButtonSection>
          <PaypalButton
            onSuccess={onPayPalSuccess}
            onPayPalError={onPayPalError}
            order={order}
            setValue={setValue}
          />
          <StripePaymentButtons
            cartTotal={order.total}
            handleShippingOptionChange={handlePaymentShippingChange}
            handlePaymentConfirm={handlePaymentRequestConfirm}
            paymentRequestOptions={{
              shippingOptions: formattedShippingOptions,
            }}
          />
        </S.ButtonSection>

        <S.Section>
          <S.SectionHeading>Shipping Information</S.SectionHeading>
          <TestTakerAddressForm
            errors={errors}
            register={register}
            setValue={setValue}
            isValidState={validateState}
          />
        </S.Section>

        <S.Section noBottom={!isCreditCardPayment}>
          <DeliveryOptionsSelector
            register={register}
            setValue={setValue}
            onChange={handleShippingChange}
            deliveryOptions={locationState.shippingMethods}
            disableOvernightShipping={shouldDisableOvernightShipping()}
          />
        </S.Section>

        {isCreditCardPayment && (
          <S.Section noBottom={true}>
            <S.SectionHeading>Payment Information</S.SectionHeading>
            <Row>
              <AcceptedPayments />
            </Row>
            <Row>
              <BillingAddressForm
                errors={errors}
                register={register}
                setValue={setValue}
                city={billingAddress.city}
                state={billingAddress.state}
                address={billingAddress.address}
                zipCode={billingAddress.zipCode}
                lastName={billingAddress.lastName}
                firstName={billingAddress.firstName}
                phoneNumber={billingAddress.phoneNumber}
              />
            </Row>
            <Row>
              <StripeFields
                ref={stripeFields}
                onCardNumberValid={onCardNumberValid}
                onCardExpiryValid={onCardExpiryValid}
                onCardCvcValid={onCardCvcValid}
              />
            </Row>
          </S.Section>
        )}
        {/*
          There is a TypeScript typing issue with the register function in react-hook-form v6.

          TODO: After upgrading to a newer version of react-hook-form, remove the usage of as any.
        */}
        <input type="hidden" {...register('last4') as any} />
        <input type="hidden" {...register('paymentProvider') as any} />
        <input type="hidden" {...register('stripeMethodName') as any} />
        <input type="hidden" {...register('stripeToken') as any} />
        <input type="hidden" {...register('paypalPayerId') as any} />
        <input type="hidden" {...register('paypalPaymentId') as any} />

        <MarketingAgreement register={register} setValue={setValue} />

        <ButtonGroup
          onCancel={onCancel}
          isLoading={isLoading}
          watchFields={watchFields}
          isCreditCardPayment={isCreditCardPayment}
          isValidCreditCard={cardNumberValid && cardExpiryValid && cardCvcValid}
        />
      </form>
    </S.ShippingPaymentContainer>
  );
};

export default ShippingPaymentInformation;
