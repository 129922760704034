import { colors } from '@everlywell/leaves';

const setTooltipIconColor = (hasError: boolean, isDisabled: boolean) => {
  if (hasError) return colors.red3;
  if (isDisabled) return colors.gray3;

  return colors.teal4;
};

export { setTooltipIconColor };
