import React from 'react';

// local imports
import { formUtils } from '@everlywell/leaves';

import { isValidEmail, isValidZipcode, normalizePhone } from 'utils/helpers';
import { US_STATES } from 'utils/constants';

import {
  Row,
  Col,
  Input,
  Dropdown,
} from '@everlywell/leaves';

interface Props {
  email?: string;
  firstName?: string;
  lastName?: string;
  address?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  phoneNumber?: string;
  inputPrefix?: string;
  register: Function;
  setValue: Function;
  isValidState?: (string: string) => boolean;
  errors?: any;
  validations?: any;
}

const defaultValidations = {
  email: {
    required: 'Please enter valid email',
    validate: (value: string) => isValidEmail(value) || 'Please enter valid email',
  },
  firstName: {
    required: 'Please enter valid first name',
  },
  lastName: {
    required: 'Please enter valid last name',
  },
  address: {
    required: 'Please enter valid address',
  },
  city: {
    required: 'Please enter valid city',
  },
  state: { required: 'Please select a valid state' },
  zipCode: {
    required: 'Please enter valid zip code',
    validate: (value: string) => isValidZipcode(value) || 'Please enter valid zip code',
  },
  phoneNumber: {
    required: false,
    invalid: 'Please enter valid phone number',
    validate: (value: string) => formUtils.validateOptionalPhoneNumber(value) || 'Please enter valid phone number',
  },
};

export function TestTakerAddressForm({
  validations = defaultValidations,
  inputPrefix = 'shippingAddress',
  errors,
  register,
  setValue,
  isValidState,
}: Props) {
  const handleZipcodeChange = (event: React.FormEvent<HTMLInputElement>) => {
    let normalizedCode = event.currentTarget.value.replace(/[^\d]/g, '');
    if (normalizedCode.length > 5) normalizedCode = normalizedCode.slice(0, 5);

    setValue && setValue(`${inputPrefix}[zipCode]`, normalizedCode);
  };

  const handlePhoneNumberChange = (event: React.FormEvent<HTMLInputElement>) => {
    if (normalizePhone) {
      setValue &&
        setValue(
          `${inputPrefix}[phoneNumber]`,
          normalizePhone(event.currentTarget.value),
        );
    }
  };

  return (
    <>
      <Row>
        <Col>
          <Input
            id="email"
            name={`${inputPrefix}[email]`}
            label="Email"
            placeholder="Email"
            ref={register(validations.email)}
            error={errors?.[inputPrefix]?.email?.message}
          />
        </Col>
      </Row>

      <Row>
        <Col xs={12} sm={6}>
          <Input
            id="first_name"
            name={`${inputPrefix}[firstName]`}
            label={'First Name'}
            placeholder={'First Name'}
            ref={register(validations.firstName)}
            error={errors?.[inputPrefix]?.firstName?.message}
          />
        </Col>
        <Col xs={12} sm={6}>
          <Input
            id="last_name"
            name={`${inputPrefix}[lastName]`}
            label="Last Name"
            placeholder="Last Name"
            ref={register(validations.lastName)}
            error={errors?.[inputPrefix]?.lastName?.message}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <Input
            id="address"
            name={`${inputPrefix}[address]`}
            label="Address"
            placeholder="Address"
            ref={register(validations.address)}
            error={errors?.[inputPrefix]?.address?.message}
          />
        </Col>
      </Row>

      <Row>
        <Col xs={12} sm={6}>
          <Input
            id="city"
            name={`${inputPrefix}[city]`}
            label="City"
            placeholder="City"
            ref={register(validations.city)}
            error={errors?.[inputPrefix]?.city?.message}
          />
        </Col>

        <Col xs={12} sm={6}>
          <Dropdown
            id="state"
            name={`${inputPrefix}[state]`}
            label="Select State"
            placeholderText="Select State"
            items={US_STATES}
            showErrorMessage
            error={errors?.[inputPrefix]?.state?.message}
            ref={
              register &&
              register({
                ...validations.state,
                validate: (value: string) => isValidState && isValidState(value),
              })
            }
          />
        </Col>
      </Row>

      <Row>
        <Col xs={12} sm={6}>
          <Input
            id="zip_code"
            name={`${inputPrefix}[zipCode]`}
            label="ZIP Code"
            placeholder="00000"
            ref={register(validations.zipCode)}
            error={errors?.[inputPrefix]?.zipCode?.message}
            onChange={handleZipcodeChange}
          />
        </Col>

        <Col xs={12} sm={6}>
          <Input
            id="phone_number"
            name={`${inputPrefix}[phoneNumber]`}
            label="Phone Number (optional)"
            placeholder="000-000-00000"
            ref={register(validations.phoneNumber)}
            error={errors?.[inputPrefix]?.phoneNumber?.message}
            onChange={handlePhoneNumberChange}
          />
        </Col>
      </Row>
    </>
  );
}

export default TestTakerAddressForm;
