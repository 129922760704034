/* eslint-disable max-lines-per-function */
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

// local imports
import { Dropdown, DropdownOptionType, Input, Row, Col, formUtils } from '@everlywell/leaves';
import { TooltipProps } from '@everlywell/leaves/lib/components/Tooltip';
import BiologicalSexField from './BiologicalSexField';
import * as S from './styles';

import {
  isValidId,
  isValidDate,
  isValidEmail,
  normalizeDate,
  isOfAge,
} from 'utils/helpers';

import { US_STATES } from 'utils/constants';

/**
 * This is the Everlywell PersonalInformation form.
 */

interface Props {
  firstName?: string;
  lastName?: string;
  dob?: string;
  shippableStates?: DropdownOptionType[];
  email?: string;
  state?: string;
  thirdPartyId?: string;
  confirmThirdPartyId?: string;
  uniqueId?: {
    format?: string;
    label?: string;
    required?: boolean;
  };
  biologicalSex?: string;
  biologicalSexTooltip?: string;
  displayBiologicalSex?: boolean;
  uniqueIdTooltip?: string;
  uniqueIdTooltipProps?: TooltipProps;
  customDateValidate?: (value: string) => string | boolean;
  isValidState?: (value: string) => boolean;
  onSubmit: (formData: any, setLoading: React.Dispatch<React.SetStateAction<boolean>>) => void;
  onCancel?: (event: React.MouseEvent<HTMLElement>) => void;
  liteDisplay?: boolean;
}

const defaultValidations = {
  firstName: {
    required: 'Please enter valid first name',
  },
  lastName: {
    required: 'Please enter valid last name',
  },
  dob: {
    required: 'Please enter valid date',
  },
  state: { required: 'Please select a valid state' },
  email: {
    validate: (value: string) => isValidEmail(value) || 'Please enter a valid email',
    required: 'Please enter a valid email',
  },
  thirdPartyId: {
    required: 'Please enter a valid ID',
  },
  confirmThirdPartyId: {
    required: true,
  },
};

// eslint-disable-next-line complexity
export function PersonalInformationForm({
  firstName,
  lastName,
  dob,
  state,
  shippableStates = US_STATES,
  email,
  thirdPartyId,
  confirmThirdPartyId,
  uniqueId,
  uniqueIdTooltip = '',
  uniqueIdTooltipProps,
  biologicalSex,
  biologicalSexTooltip,
  displayBiologicalSex = false,
  isValidState,
  customDateValidate,
  onSubmit,
  onCancel,
  liteDisplay = false,
}: Props) {
  const {
    register,
    trigger,
    setValue,
    handleSubmit,
    errors,
    watch,
    control,
    formState: { isValid }
  } = useForm({
    mode: 'all',
    defaultValues: {
      firstName,
      lastName,
      dob,
      email,
      state,
      thirdPartyId,
      confirmThirdPartyId,
      biologicalSex,
    },
  });
  const [loading, setLoading] = useState(false);
  const SM_SIZE_DISPLAY = liteDisplay ? 12 : 6;

  const watchFields = watch();
  const uniqueIdFormat = uniqueId?.format || formUtils.USER_ID_FORMAT_REGEX;

  const handleOnSubmit = () => {
    setLoading(true);
    return onSubmit(watchFields, setLoading);
  };

  const handleDobChange = (event: React.FormEvent<HTMLInputElement>) => {
    if (normalizeDate) {
      const normalized = normalizeDate(event.currentTarget.value);
      setValue('dob', normalized);
    }
  };

  return (
    <S.FormContainer onSubmit={handleSubmit(handleOnSubmit)}>
      <Row>
        <Col xs={12} sm={SM_SIZE_DISPLAY}>
          <Input
            id="firstName"
            name="firstName"
            label="First Name"
            placeholder="First Name"
            ref={register(defaultValidations.firstName)}
            error={errors?.firstName?.message}
            required
          />
        </Col>
        <Col xs={12} sm={SM_SIZE_DISPLAY}>
          <Input
            id="lastName"
            name="lastName"
            label="Last Name"
            placeholder="Last Name"
            ref={register(defaultValidations.lastName)}
            error={errors?.lastName?.message}
            required
          />
        </Col>
      </Row>

      {displayBiologicalSex && (
        <Row>
          <Col>
            <BiologicalSexField
              control={control}
              setValue={setValue}
              tooltip={biologicalSexTooltip}
              selected={watchFields.biologicalSex || ''}
            />
          </Col>
        </Row>
      )}

      <Row>
        <Col>
          <Input
            id="email"
            name="email"
            label="Email"
            placeholder="johndoe@email.com"
            ref={register(defaultValidations.email)}
            error={errors?.email?.message}
            required
          />
        </Col>
      </Row>

      <Row>
        <Col xs={12} sm={SM_SIZE_DISPLAY}>
          <Input
            id="dob"
            name="dob"
            label="Date of Birth"
            placeholder="MM/DD/YYYY"
            ref={register({
              ...defaultValidations.dob,
              validate: (value: string) => {
                if (!isValidDate(value)) return 'Please enter valid date'
                if (!isOfAge(value)) return "Must be 18+ to order this test."
                if (customDateValidate) return customDateValidate(value);

                return true;
              }
            })}
            error={errors?.dob?.message}
            onChange={handleDobChange}
            required
          />
        </Col>

        {!liteDisplay && (
          <Col xs={12} sm={SM_SIZE_DISPLAY}>
            <Dropdown
              items={shippableStates}
              id="state"
              name="state"
              label="Select State"
              placeholderText="Select State"
              showErrorMessage
              ref={register({
                ...defaultValidations.state,
                validate: (value) => isValidState && isValidState(value),
              })}
              error={errors?.state?.message}
            />
          </Col>
        )}
      </Row>

      {uniqueId && !!uniqueId.required && (
        <Row>
          <Col xs={12} sm={SM_SIZE_DISPLAY}>
            <Input
              id="thirdPartyId"
              name="thirdPartyId"
              label={uniqueId?.label || 'Third Party ID'}
              placeholder="ID associated to you"
              onChange={() => { trigger('confirmThirdPartyId') }}
              ref={register({
                required: true,
                validate: (value) => isValidId(value, uniqueIdFormat) || 'Please enter a valid ID',
              })}
              error={errors?.thirdPartyId?.message}
              tooltip={uniqueIdTooltip}
              tooltipProps={uniqueIdTooltipProps}
              required
            />
          </Col>
          <Col xs={12} sm={SM_SIZE_DISPLAY}>
            <Input
              id="confirmThirdPartyId"
              name="confirmThirdPartyId"
              label={`Confirm ${uniqueId?.label ? uniqueId.label : 'Third Party ID'}`}
              placeholder="Re-enter ID"
              ref={register({
                required: 'Please enter a valid ID',
                validate: (value) => {
                  return (watchFields.thirdPartyId === value) || 'IDs do not match. Please try again.';
                }
              })}
              error={errors?.confirmThirdPartyId?.message}
              required
            />
          </Col>
        </Row>
      )}

      <S.ButtonGroupContainer reverse={true} liteDisplay={liteDisplay}>
        <S.PrimaryButton
          hasArrow={true}
          isLoading={loading}
          appearance="primary"
          isDisabled={loading || (displayBiologicalSex && !watchFields.biologicalSex) || !isValid}
        >
          Continue
        </S.PrimaryButton>
        {onCancel && (
          <S.SecondaryButton onClick={onCancel} appearance="secondary">
            Cancel
          </S.SecondaryButton>
        )}
      </S.ButtonGroupContainer>
    </S.FormContainer>
  );
}

export default PersonalInformationForm;
