import {
  H4,
  size,
  colors,
  typography,
  mediaQueries,
  creditCardsIcons,
} from '@everlywell/leaves';
import styled from 'styled-components';

const ReviewOrderContainer = styled.div`
  padding: 60px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  border-radius: 1px;
  margin: ${size.sm}px 0;
  background-color: ${colors.white};
  box-shadow: 0 2.5px 10px 0 rgba(189, 196, 197, 0.5);

  ${mediaQueries.forPhoneOnly} {
    flex-direction: column;
    padding: 45px ${size.lg}px ${size.lg}px;
  }
`;

const InfoBlock = styled.div`
  margin-bottom: ${size.xl1}px;
`;

const InfoContainer = styled.div`
  flex: 1;
  word-wrap: break-word;
`;

const Title = styled(H4)`
  ${typography.subtitle}
  font-size: 20px;
  margin-bottom: ${size.md}px;
`;

const DeliveryTitle = styled.div`
  ${typography.subtitle}
`;

const Text = styled.div<{ small?: boolean; bold?: boolean; provider?: boolean; }>`
  font-size: ${(props) => (props.small ? size.md : '18')}px;
  font-weight: normal;
  padding-top: ${(props) => (props.provider ? '3px' : '0px')};
  line-height: ${(props) =>
    props.bold ? '1.33' : props.small ? '1.63' : '1.78'};
  color: ${(props) => (props.bold ? colors.gray5 : colors.gray4)};
  letter-spacing: ${(props) => (props.bold ? '0.45px' : 'normal')};
`;

const PaymentContainer = styled.div`
  display: flex;

  ${mediaQueries.forPhoneOnly} {
    flex-direction: column;
  }
`;

const PaymentProvider = styled.div<{ provider: 'masterCard' | 'visa' | 'americanExpress' | 'discover' | 'payPal'; }>`
  width: 53px;
  height: 37px;
  margin-right: ${size.md}px;
  background-size: 53px 37px !important;
  background: url(${(props) => creditCardsIcons[props.provider]});
`;

export {
  InfoBlock,
  InfoContainer,
  PaymentContainer,
  PaymentProvider,
  ReviewOrderContainer,
  Text,
  Title,
  DeliveryTitle,
};
