export const PAGE_INFO = {
  0: {
    title: 'Personal Information',
    name: 'Enterprise - Personal Information',
  },
  1: {
    title: 'Available Tests',
    name: 'Enterprise - Available Tests',
  },
  2: {
    title: 'Shipping Information',
    name: 'Enterprise - Shipping Information',
  },
  3: {
    title: 'Review Order',
    name: 'Enterprise - Review Order',
  },
};
