import { PaymentRequestButtonElement, useStripe } from '@stripe/react-stripe-js';
import {
  PaymentRequest,
  PaymentRequestWallet,
  PaymentRequestShippingOption,
  PaymentRequestTokenEvent,
  PaymentRequestUpdateDetails
} from '@stripe/stripe-js';
import React, { useState, useReducer, useEffect } from 'react';

// local imports
import * as S from './styles';

interface PaymentRequestOptions {
  name: boolean;
  email: boolean;
  phone: boolean;
  shipping: boolean;
  disableWallets: PaymentRequestWallet[];
  shippingOptions: PaymentRequestShippingOption[]
}

export interface StripePaymentButtonProps {
  optionType?:
  | 'default'
  | 'book'
  | 'buy'
  | 'donate'
  | 'check-out'
  | 'subscribe'
  | 'reload'
  | 'add-money'
  | 'top-up'
  | 'order'
  | 'rent'
  | 'support'
  | 'contribute'
  | 'tip';
  optionTheme?: 'dark' | 'light' | 'light-outline';
  onPaymentConfirm?: (cb: () => void) => void;
  onShippingOptionChange?: (cb: () => void) => void;
  paymentRequestOptions: Partial<PaymentRequestOptions>;
  handlePaymentConfirm: (data: Omit<PaymentRequestTokenEvent, 'token' | 'complete'>, token: PaymentRequestTokenEvent['token'], complete: PaymentRequestTokenEvent['complete']) => void;
  handleShippingOptionChange: (updateWith: (details: PaymentRequestUpdateDetails) => void, shippingOption: PaymentRequestShippingOption) => void;
  cartTotal: string;
}

interface ReducerStateAndAction {
  state: {
    [key: string]: unknown;
  };
  action: {
    type: string;
  }
}

const defaultRequestOptions: PaymentRequestOptions = {
  name: true,
  email: true,
  phone: true,
  shipping: true,
  disableWallets: [],
  shippingOptions: [],
};

export const StripePaymentButtons = ({
  optionType = 'default',
  optionTheme = 'dark',
  onPaymentConfirm = (cb) => cb(),
  onShippingOptionChange = (cb) => cb(),
  paymentRequestOptions = defaultRequestOptions,
  handlePaymentConfirm,
  handleShippingOptionChange,
  cartTotal,
}: StripePaymentButtonProps) => {
  const stripe = useStripe();
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest>();

  paymentRequestOptions = {
    ...defaultRequestOptions,
    ...paymentRequestOptions,
  };

  const reducer = (state: { [key: string]: unknown }, action: { type: string; }) => {
    switch (action.type) {
      case 'Set Stripe PaymentRequest':
        const amount = +cartTotal.replace(/\./g, '');
        const pr = stripe?.paymentRequest({
          country: 'US',
          currency: 'usd',
          total: {
            label: `Everly Wellness, Inc`,
            amount,
          },
          requestPayerName: paymentRequestOptions.name,
          requestPayerEmail: paymentRequestOptions.email,
          requestPayerPhone: paymentRequestOptions.phone,
          requestShipping: paymentRequestOptions.shipping,
          shippingOptions: paymentRequestOptions.shippingOptions,
          disableWallets: paymentRequestOptions.disableWallets,
        });

        pr?.canMakePayment().then((result) => {
          if (result) setPaymentRequest(pr);
        });

        pr?.on('token', (event) => {
          const { token, complete, ...data } = event;
          onPaymentConfirm(() => {
            if (handlePaymentConfirm)
              handlePaymentConfirm(data, token, complete);
          });
        });

        pr?.on('shippingoptionchange', (event) => {
          const { updateWith, shippingOption } = event;
          onShippingOptionChange(() => {
            if (handleShippingOptionChange)
              handleShippingOptionChange(updateWith, shippingOption);
          });
        });

        pr?.on('cancel', () => {
          // Probably want to track some analytics here
        });

        return { ...state, paymentRequest };
      default:
        throw new Error();
    }
  };

  const [, dispatch] = useReducer<React.Reducer<ReducerStateAndAction['state'], ReducerStateAndAction['action']>>(reducer, {});

  useEffect(() => {
    if (!stripe) return;
    dispatch({ type: 'Set Stripe PaymentRequest' });
  }, [stripe]);

  const options = {
    style: {
      paymentRequestButton: {
        type: optionType,
        theme: optionTheme,
        height: '48px',
      },
    },
  };

  return (
    <S.DevicePaymentButtons xs={12} sm={6}>
      {paymentRequest && (
        <PaymentRequestButtonElement options={{ ...options, paymentRequest }} />
      )}
    </S.DevicePaymentButtons>
  );
};

export default StripePaymentButtons;
