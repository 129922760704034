import { Row, Col, Help, typography, colors } from '@everlywell/leaves';
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import React, { useEffect, forwardRef, useImperativeHandle } from 'react';
// local imports
import { STRIPE } from 'utils/constants';

import { getStripePaymentDetails } from './helpers';
import * as S from './styles';

interface Props {
  onCardNumberValid: Function;
  onCardExpiryValid: Function;
  onCardCvcValid: Function;
  disabled?: boolean;
}

export const StripeFields = forwardRef<any, Props>(
  ({ onCardNumberValid, onCardExpiryValid, onCardCvcValid, disabled }, ref) => {
    const elements = useElements();
    const stripe = useStripe();

    // Calling `useElements` and `useStripe` causes the component to
    // render twice in succession during the initial render. In order to prevent
    // loading issues with our PayPal integration we have move the method that
    // requires these callbacks into this component

    // When we need to grab the stripe payment details we call the
    // `getStripeDetails` on the ref set in the parent component
    // allowing us to get the stripe token without causing the parent
    // component to render twice. Not the best solution, in that it breaks
    // react paradigms...but it works for now. ¯\_(ツ)_/¯

    useImperativeHandle(ref, () => ({
      getStripeDetails: async () => {
        try {
          if (stripe && elements) {
            return await getStripePaymentDetails(stripe, elements);
          }
        } catch (e) {
          return e;
        }
      },
    }));

    useEffect(() => {
      if (elements) {
        const { create } = elements;

        // Shared element styles
        const style = {
          base: {
            color: colors.gray4,
            fontFamily: typography.type.nexa,
            fontSize: '18px',
            lineHeight: 1.78,
            fontSmoothing: 'antialiased',
            '::placeholder': {
              color: colors.gray3,
              fontWeight: typography.weight.book,
            },
          },
          invalid: {},
        };

        // Create and mount form fields
        // @ts-ignore
        create('cardNumber', {
          showIcon: false,
          placeholder: '0000-0000-0000-0000',
          style,
        }).mount('#creditCardNumber');

        // @ts-ignore
        create('cardExpiry', {
          placeholder: 'MM/YY',
          style,
        }).mount('#creditCardExpiration');

        // @ts-ignore
        create('cardCvc', {
          placeholder: '000',
          style,
        }).mount('#creditCardCvc');
      }
    }, [elements]);

    useEffect(() => {
      if (elements) {
        const { getElement } = elements;
        const cardNumber = getElement('cardNumber');
        const cardExpiry = getElement('cardExpiry');
        const cardCvc = getElement('cardCvc');

        // Disable Stripe payment inputs when submitting order
        cardNumber?.update({ disabled: disabled });
        cardExpiry?.update({ disabled: disabled });
        cardCvc?.update({ disabled: disabled });
      }
    }, [disabled, elements]);

    // This useEffect hook is used to attach change handlers to
    // the Stripe Card fields. Once there are no detectable errors
    // and the input is assume to be complete the corresponding
    // validation is set to true enabling submission.

    useEffect(() => {
      if (elements) {
        const cardNumber = elements.getElement(CardNumberElement);
        const expirationDate = elements.getElement(CardExpiryElement);
        const cvcNumber = elements.getElement(CardCvcElement);

        cardNumber?.on('change', (event) =>
          onCardNumberValid(!event.error && event.complete),
        );
        expirationDate?.on('change', (event) =>
          onCardExpiryValid(!event.error && event.complete),
        );
        cvcNumber?.on('change', (event) =>
          onCardCvcValid(!event.error && event.complete),
        );
      }
    }, [elements, onCardNumberValid, onCardExpiryValid, onCardCvcValid]);

    return (
      <>
        <S.FlexOrder order="2" mobileOrder="2">
          <S.StripeInputWrapper>
            <S.StripeInput
              id="creditCardNumber"
              data-testid={STRIPE.CARD_NUMBER_INPUT}
              name="Card Number"
            />
            <S.StripeLabel htmlFor="Card Number">Card Number</S.StripeLabel>
          </S.StripeInputWrapper>
        </S.FlexOrder>
        <S.FlexOrder order="3" mobileOrder="3">
          <Row>
            <Col xs md>
              <S.StripeInputWrapper>
                <S.StripeInput
                  id="creditCardExpiration"
                  name="Expiration Date"
                  data-testid={STRIPE.EXPIRATION_DATE_INPUT}
                />
                <S.StripeLabel htmlFor="Expiration Date">
                  Expiration Date
                </S.StripeLabel>
              </S.StripeInputWrapper>
            </Col>
            <Col xs md>
              <S.StyledInputContainer>
                <S.StripeInputWrapper>
                  <S.StripeInput
                    id="creditCardCvc"
                    name="CVC"
                    data-testid={STRIPE.CVC_INPUT}
                  />
                  <S.StripeLabel htmlFor="CVC">CVC</S.StripeLabel>
                </S.StripeInputWrapper>
                <S.TooltipWrapper>
                  <S.CvcTooltip
                    animationSpeed="normal"
                    content="3-digit security code usually found on the back of your card. American Express cards have a 4-digit code located on the front."
                    position="top"
                    arrow="right"
                    tooltipBoxClass="tooltipBox"
                  >
                    <Help />
                  </S.CvcTooltip>
                </S.TooltipWrapper>
              </S.StyledInputContainer>
            </Col>
          </Row>
        </S.FlexOrder>
      </>
    );
  },
);

export default StripeFields;
