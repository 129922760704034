import styled from 'styled-components';

import { Col, typography, size, creditCardsIcons } from '@everlywell/leaves';

const AcceptedCards = styled(Col)`
  display: flex;
  align-items: center;
  margin-bottom: ${size.xl1}px;
`;

const CreditCardProviders = styled.ul`
  list-style: none;

  > li {
    width: 53px;
    height: 37px;
    display: inline-block;
    margin-right: ${size.md}px;
    background-size: 53px 37px;
    background-repeat: no-repeat;
    background-position: center;

    &:last-child {
      margin-right: ${size.lg}px;
    }
  }
`;

const MasterCard = styled.li`
  background: url(${creditCardsIcons.masterCard});
`;

const Visa = styled.li`
  background: url(${creditCardsIcons.visa});
`;

const AmericanExpress = styled.li`
  background: url(${creditCardsIcons.americanExpress});
`;

const Discover = styled.li`
  background: url(${creditCardsIcons.discover});
`;

const Note = styled.div`
  ${typography.bodyTextSmall}
`;

export {
  AcceptedCards,
  CreditCardProviders,
  MasterCard,
  Visa,
  AmericanExpress,
  Discover,
  Note,
};
