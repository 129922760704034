import { OrderSummary, Localize } from '@everlywell/leaves';
import { useLocation } from '@reach/router';
// local imports
import { TestTakerPaymentContext } from 'contexts/test-taker-payment';
import React, { useState, useEffect, useContext } from 'react';

interface OrderSummaryWrapperProps {
  buttonEnabled: boolean;
  onPlaceOrder: () => void;
}

interface LocationState {
  contentfulProductIds: number[];
  products: Product[]
}

const TestsDisclaimer = () => {
  return (
    <>
      Each kit includes the cost of physician services from PWNHealth ($8 per
      Covid DTC test, $4.75 per test for all other tests, no fee for Covid rapid
      tests). Once your results are released, an itemized receipt will be
      available for download and submission for reimbursement. Reimbursement is
      at the discretion of your health insurance provider.{' '}
      <a href="https://support.everlywell.com/article/621-will-my-covid-19-test-home-collection-kit-be-covered-by-insurance">
        Learn more.
      </a>
    </>
  );
};

const QuestionsDisclaimer = () => {
  return (
    <>
      Do you have questions? Feel free to call Customer Care at&nbsp;
      <Localize name="phone-number">
        <a href="tel:+1 855-463-1670">+1-855-463-1670</a>
      </Localize>{' '}
      for any product help or to request to connect with a physician
      pre-purchase.
    </>
  );
};

const disclaimersList = [
  {
    type: 'tests',
    highlighted: true,
    component: <TestsDisclaimer />,
  },
  {
    type: 'tests',
    highlighted: false,
    component: <QuestionsDisclaimer />,
  },
];

const OrderSummaryWrapper = ({ buttonEnabled, onPlaceOrder }: OrderSummaryWrapperProps) => {
  const { state } = useLocation();
  const locationState = state as LocationState;
  const [products, updateProducts] = useState([] as Product[]);
  const { selectedShippingMethod, lineItems } = useContext(
    TestTakerPaymentContext,
  );

  useEffect(() => {
    const contentfulProductIds = locationState.contentfulProductIds;
    const productsList = locationState?.products;

    if (productsList === undefined) return;

    const updatedProducts = productsList.map((product: Product) => {
      const productIndex = contentfulProductIds.indexOf(product.productId);
      const { quantity } = lineItems[productIndex];

      return { ...product, quantity };
    });

    updateProducts(updatedProducts);
  }, [locationState.products, locationState.contentfulProductIds, lineItems]);

  return (
    <OrderSummary
      // @ts-ignore
      products={products}
      onClick={onPlaceOrder}
      isDisabled={!buttonEnabled}
      disclaimersList={disclaimersList}
      // @ts-ignore
      shippingMethod={selectedShippingMethod}
    />
  );
};

export default OrderSummaryWrapper;
