import { colors, Col, mediaQueries } from '@everlywell/leaves';
import styled from 'styled-components';

const StyledMainContainer = styled.div<{ showOrderSummary?: boolean; }>`
  margin: 0 auto;
  max-width: ${(props) => (props.showOrderSummary ? '1440' : '900')}px;
`;

const BackgroundContainer = styled.div`
  padding: 40px 70px;
  background-color: ${colors.green1};

  ${mediaQueries.forPhoneOnly} {
    padding: 30px;
    #edit-link {
      margin-bottom: 15px;
      display: flex;
      justify-content: right;
    }
  }
`;

const WrappedSectionCol = styled(Col)``;

const OrderSummaryCol = styled(Col)``;

export {
  BackgroundContainer,
  OrderSummaryCol,
  StyledMainContainer,
  WrappedSectionCol,
};
