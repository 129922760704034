import { H4, Row } from '@everlywell/leaves';
import React from 'react';

// local imports
import OrderSummaryWrapper from './order-summary';
import * as S from './styles';

interface Props {
  title?: string;
  paymentEnabled?: boolean;
  showOrderSummary?: boolean;
  onPlaceOrder?: () => void;
  children: JSX.Element | JSX.Element[];
}

const TestTakerPaymentWrapper = ({
  title,
  paymentEnabled,
  showOrderSummary,
  onPlaceOrder,
  children,
}: Props) => {
  return (
    <S.BackgroundContainer>
      <S.StyledMainContainer showOrderSummary={showOrderSummary}>
        {title && <H4>{title}</H4>}

        <Row>
          <S.WrappedSectionCol lg={showOrderSummary ? 9 : 12}>
            {children}
          </S.WrappedSectionCol>

          {showOrderSummary && (
            <S.OrderSummaryCol lg={3}>
              <OrderSummaryWrapper
                onPlaceOrder={onPlaceOrder!}
                buttonEnabled={paymentEnabled!}
              />
            </S.OrderSummaryCol>
          )}
        </Row>
      </S.StyledMainContainer>
    </S.BackgroundContainer>
  );
};

export default TestTakerPaymentWrapper;
