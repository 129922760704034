import { colors, size, mediaQueries, Container, Col } from '@everlywell/leaves';
import styled from 'styled-components';

const FormContainer = styled(Container)`
  max-width: 869px;
  border-radius: 1px;
  margin: ${size.sm}px 0;
  background-color: ${colors.white};
  padding: 55px ${size.xl4}px 55px 63px;
  box-shadow: 0 2.5px 10px 0 rgba(189 196 197 / 50%);

  ${mediaQueries.forPhoneOnly} {
    padding: 45px ${size.lg}px ${size.lg}px;
  }
`;

const OptionsWrapper = styled(Col)`
  padding-left: ${size.lg}px;

  span + span {
    text-transform: uppercase;
  }
`;

export { FormContainer, OptionsWrapper };
