import { Products, Localize } from '@everlywell/leaves';
import { useProductsList } from 'components/forms/QuantityDropdown/hooks';
import { WizardContext } from 'contexts/wizard';
import { useFindManyProducts } from 'hooks';
import React, { useContext, useEffect } from 'react';
import { analytics, sanitize, truncate } from 'utils/helpers';

// local imports
import { PAGE_INFO } from '../constants';
import * as S from './styles';

interface Props {
  products: Product[],
  moveForward: () => void;
}

const TestsStep = ({ products, moveForward }: Props) => {
  const {
    firstName,
    isQuotaFull,
    kitSelectionEnabled,
    allowedQuantityPerProduct,
    contentfulProductIds,
  } = useContext(WizardContext);

  const foundProducts = useFindManyProducts({
    productIds: contentfulProductIds,
  });

  if (process.env.GATSBY_ACCESS_CODE_ELIGIBLITY_CHECK_ENABLED === 'true') {
    products = foundProducts;
  }

  const productsList = useProductsList(
    products,
    'enterprise',
    kitSelectionEnabled,
    allowedQuantityPerProduct,
  );

  const eventData = {
    page_name: PAGE_INFO[1].name,
  };

  const handleClick = () => {
    analytics.track('CTA Clicked', {
      ...eventData,
      test_type: productsList.map((product) => product.displayName),
      test_id: productsList.map((product) => product.productId),
      test_quantity: productsList.length,
    });

    moveForward();
  };

  useEffect(() => {
    analytics.track('Page Viewed', {
      ...eventData,
      test_quantity: productsList.length,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <S.Container>
      <S.Greeting>
        Welcome, <Localize name="first-name">{firstName}</Localize>!
      </S.Greeting>
      <S.Description>
        With Everlywell, you can access lab tests from the comfort of your home.
      </S.Description>

      <Products
        source="enterprise"
        onClick={handleClick}
        /*
          Leaves Product component has optional props that are typed as required,
          and props that are no longer being used.

          TODO: On Leaves, remove imageURL prop, and make price prop optional.
        */
        // @ts-ignore
        productsList={productsList}
        isQuotaFull={isQuotaFull}
        truncationStrategy={truncate}
        sanitationStrategy={sanitize}
      />
    </S.Container>
  );
};

export default TestsStep;
