import { H2, mediaQueries, typography } from '@everlywell/leaves';
import styled from 'styled-components';
import { MainContainerWrapper } from 'styles/global';

const Container = styled(MainContainerWrapper)`
  margin-bottom: 40px;
`;

const Greeting = styled(H2)`
  text-align: center;
  margin: 0;

  ${mediaQueries.forTabletHorizontalUp} {
    padding: 0;
  }
`;

const Description = styled.p`
  ${typography.bodyTextSmall}
  text-align: center;
  margin: 0 0 2.5rem;

  ${mediaQueries.forTabletHorizontalUp} {
    font-size: 1.125rem;
    line-height: 1.78;
    margin: 0 1.5rem 3.5rem;

    padding: 0;
  }
`;

export { Container, Greeting, Description };
