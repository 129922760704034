import { size, typography } from '@everlywell/leaves';
import styled from 'styled-components';

const Wrapper = styled.div`
  margin-top: ${size.xl2}px;
`;

const Title = styled.h4`
  ${typography.h4Text};
  margin-bottom: ${size.xs3}px;
  letter-spacing: 0.47px;
  padding-top: 5px;
  line-height: 1.4;
`;

const Description = styled.div`
  margin-top: 14px;
  padding-right: 1px;
  ${typography.bodyTextSmall};
  margin-bottom: ${size.sm}px;
  line-height: 1.75;
  letter-spacing: normal;

  ${(mediaQuery) => mediaQuery.theme.forTabletHorizontalUp} {
    margin-top: 0;
  }
`;

export { Wrapper, Title, Description };
