import { Dropdown, size } from '@everlywell/leaves';
import styled from 'styled-components';

const QuantityDropdown = styled(Dropdown)`
  display: flex;
  margin-top: ${size.md}px;
  flex-direction: row;
  align-items: center;
  gap: ${size.md}px;
`;

export { QuantityDropdown };
