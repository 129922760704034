import { H3, Localize, LocalizeDisabled } from '@everlywell/leaves';
import { OrderPlaced as OrderPlacedIcon } from 'components/icons';
import { WizardContext } from 'contexts/wizard';
import React, { useContext, useEffect } from 'react';
import { MainContainerWrapper } from 'styles/global';
import { HELP_FORM_ADDRESS } from 'utils/constants';
import { logout } from 'utils/services/auth';

import * as S from './styles';

const OrderPlaced = () => {
  useEffect(() => {
    logout();
  }, []);

  const { orderNumber, orderEmail } = useContext(WizardContext);

  let formattedOrderNumer = `${orderNumber}`.slice(0, 10);

  return (
    <S.OuterWrapper>
      <S.InnerWrapper>
        <MainContainerWrapper>
          <span
            data-track-custom-data={JSON.stringify({
              title: 'Order Placed',
            })}
            hidden
          />
          <S.Header>
            <H3>Order is placed</H3>
            <S.HeaderIconWrapper>
              <OrderPlacedIcon />
            </S.HeaderIconWrapper>
          </S.Header>

          <S.OrderNumberWrapper>
            <S.OrderNumberLabel>Order number: </S.OrderNumberLabel>
            <S.OrderNumberValue>
              <LocalizeDisabled>{formattedOrderNumer}</LocalizeDisabled>
            </S.OrderNumberValue>
          </S.OrderNumberWrapper>

          <S.HintWrapper>
            Your order is now processing. A copy of your order details will be
            sent to <Localize name="user-email">{orderEmail}</Localize>.
          </S.HintWrapper>

          <S.HintWrapper>
            If you have any questions about tracking your shipment, or would
            like to update your preferred shipping address, please contact the
            Everlywell Customer Care team by submitting the request form on{' '}
            <S.SupportFormLink href={`https://${HELP_FORM_ADDRESS}`} target="_blank">
              {HELP_FORM_ADDRESS}
            </S.SupportFormLink>
            .
          </S.HintWrapper>
        </MainContainerWrapper>
      </S.InnerWrapper>
    </S.OuterWrapper>
  );
};

export default OrderPlaced;
