import React from 'react';
import { Controller } from 'react-hook-form';

// local imports
import { setTooltipIconColor } from './helpers';
import { Tooltip, RadioButton, InformationV2 } from '@everlywell/leaves';
import * as S from './styles';

interface Props {
  control: any;
  setValue: any;
  selected: string;
  fieldName?: string;
  tooltip?: string;
  hasError?: boolean;
  isDisabled?: boolean;
}

const radioButtons = [
  { label: 'Female', value: 'female' },
  { label: 'Male', value: 'male' },
];

export const BiologicalSexField = ({
  control,
  setValue,
  selected,
  fieldName = 'biologicalSex',
  tooltip = 'Sex assigned at birth affects how your results are reported',
  hasError = false,
  isDisabled = false,
}: Props) => {
  return (
    <>
      <S.Label
        htmlFor={fieldName}
        data-testid={`${fieldName}-label`}
        hasTooltip={true}
      >
        Sex Assigned At Birth
        <Tooltip
          content={tooltip}
          animationSpeed="normal"
          position="top"
          arrow="center"
          className="labelTooltip"
          tooltipBoxClass="labelTooltipBox"
        >
          <InformationV2
            className="labelTooltipIcon"
            color={setTooltipIconColor(hasError, isDisabled)}
            hasError={hasError}
            isDisabled={isDisabled}
          />
        </Tooltip>
      </S.Label>
      <S.RadioGroup>
        <Controller
          control={control}
          name={fieldName}
          defaultValue=""
          as={
            <>
              {radioButtons.map((radio) => (
                <S.RadioButtonWrapper
                  checked={selected === radio.value}
                  key={`${fieldName}-${radio.value}`}
                >
                  <RadioButton
                    id={`${fieldName}-${radio.value}`}
                    name={fieldName}
                    label={radio.label}
                    value={radio.value}
                    onChange={(event: React.FormEvent<HTMLInputElement>) => {
                      setValue(fieldName, event.currentTarget.value);
                    }}
                  />
                </S.RadioButtonWrapper>
              ))}
            </>
          }
        />
      </S.RadioGroup>
    </>
  );
};

export default BiologicalSexField;
