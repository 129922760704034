import { TertiaryLink, Localize, LocalizeDisabled } from '@everlywell/leaves';
import { useLocation } from '@reach/router';
import orderComplete from 'api/test-taker-paid/order-complete';
import { TestTakerPaymentContext } from 'contexts/test-taker-payment';
import { navigate } from 'gatsby';
import React, { useContext } from 'react';
import { sentenceCase } from 'sentence-case';
// local imports
import { analytics, logError, displayError } from 'utils/helpers';
import { setInnerNavigate } from 'utils/services/auth';

import TestTakerPaymentWrapper from '../index';
import * as S from './styles';

const CONFIRMATION_PATH = '/app/payment-steps/success';

type PaymentProvider = 'masterCard' | 'visa' | 'americanExpress' | 'discover' | 'payPal';

interface ShippingAddress {
  email: string;
  firstName: string;
  lastName: string;
  address: string;
  city: string;
  zipCode: string;
  state: string;
  phoneNumber: string;
}

interface LocationState {
  shippingAddress: ShippingAddress;
  paymentProvider: PaymentProvider;
  cardNumber: string;
  accessCode: string;
  contentfulProductIds: number[];
  spreeOrder: TestTakerOrder;
  paypalParams: unknown;
  stripeParams: unknown;
  stripeMethodName: string;
  shippingMethodId: number;
  paypalPayerId?: string;
  paypalPaymentId?: string;
}

interface OrderPayload {
  ship_address: Partial<ShippingAddress>;
  bill_address: Partial<ShippingAddress>;
  shipping_method_id: number;
  payment_provider: string;
  paypal_params?: unknown;
  stripe_params?: unknown;
};

const InfoBlock = ({ title, children }: { title: string; children: JSX.Element | JSX.Element[] }) => {
  return (
    <S.InfoBlock>
      <S.Title>{title}</S.Title>
      {children}
    </S.InfoBlock>
  );
};

const PaymentProviderInfo = ({ paymentProvider, lastDigits }: { paymentProvider: PaymentProvider; lastDigits: string; }) => {
  return (
    <>
      <Localize name="payment-provider">
        {sentenceCase(paymentProvider).toUpperCase()}
      </Localize>
      &nbsp;ending in&nbsp;
      <Localize name="last-digits">{lastDigits}</Localize>
    </>
  );
};

const ReviewOrderWrapper = () => {
  const location = useLocation();
  const locationState = location.state as LocationState;

  const { email, firstName, lastName, address, city, zipCode, state } =
    locationState.shippingAddress;

  const paymentProvider = locationState.paymentProvider;
  const lastDigits = locationState.cardNumber?.slice(-4);

  const { selectedShippingMethod } = useContext(TestTakerPaymentContext);

  const eventData = {
    page_name: 'Test Taker Paid - Order Review',
    title: 'Review Order',
  };

  const goToConfirmation = () => {
    const order = locationState.spreeOrder;
    analytics.track('CTA Clicked', {
      text: 'Place Order Button Selected',
      payment_method: paymentProvider,
      destination: CONFIRMATION_PATH,
      type: 'Button',
      ...eventData,
    });

    analytics.track('Purchase', {
      text: 'Place Order',
      payment_method: paymentProvider,
      product_id: order.line_items.map((li: { product_id: number; }) => li.product_id),
      variant_id: order.line_items.map((li: { variant_id: number; }) => li.variant_id),
      order_value: order.total,
      access_code: locationState.accessCode,
    });

    setInnerNavigate();
    navigate(CONFIRMATION_PATH, { state: locationState });
  };

  const goToEdit = () => {
    setInnerNavigate();

    let paymentContext = locationState;
    delete paymentContext.paypalPayerId;
    delete paymentContext.paypalPaymentId;

    analytics.track('CTA Clicked', {
      ...eventData,
      text: 'Shipping Information "Change" Button',
      type: 'Link',
    });

    navigate('/app/payment-steps/payment', { state: paymentContext });
  };

  const handlePlaceOrder = () => {
    const paymentContext = locationState;
    const order = paymentContext?.spreeOrder;
    const paypalParams = paymentContext?.paypalParams;
    const stripeParams = paymentContext?.stripeParams;
    const shippingMethodId = paymentContext?.shippingMethodId;
    const paymentProvider = paymentContext?.paymentProvider;
    const contentfulProductIds = locationState.contentfulProductIds || [];

    const shipAddressParams = {
      firstname: paymentContext?.shippingAddress?.firstName,
      lastname: paymentContext?.shippingAddress?.lastName,
      country: 'US',
      address1: paymentContext?.shippingAddress?.address,
      city: paymentContext?.shippingAddress?.city,
      state: paymentContext?.shippingAddress?.state,
      zipcode: paymentContext?.shippingAddress?.zipCode,
      phone: paymentContext?.shippingAddress?.phoneNumber,
    };

    let orderPayload: OrderPayload = {
      ship_address: shipAddressParams,
      bill_address: shipAddressParams, //<--Submit the same as bill address in case of Paypal
      shipping_method_id: shippingMethodId,
      payment_provider: paymentProvider,
    };

    let isStripePayment = [
      'visa',
      'discover',
      'masterCard',
      'americanExpress',
    ].includes(paymentProvider);
    const stripeMethodName = isStripePayment
      ? paymentContext?.stripeMethodName
      : null;
    let isPayPalPayment = paymentProvider === 'payPal';

    if (isPayPalPayment) {
      orderPayload['paypal_params'] = paypalParams;
    } else if (isStripePayment) {
      orderPayload['stripe_params'] = stripeParams;
    } else {
      return displayError(
        'There was an issue creating your order. Payment method not supported. Please try again.',
      );
    }

    return orderComplete(order.number, { order: orderPayload })
      .then(goToConfirmation)
      .catch((error) => {
        const errorMessage = error?.response?.data?.errors;
        const logErrorMessage = `Opt-In - Taker paid - ${isPayPalPayment ? 'PayPal' : 'Stripe'
          } Order completion failed`;

        let correlation_ids = {
          order_id: order.number,
          shipping_method_id: shippingMethodId,
          paypal_params: paypalParams,
          stripe_params: stripeParams,
          contentful_product_ids: contentfulProductIds,
        };

        if (isPayPalPayment) {
          delete correlation_ids.stripe_params;
        } else {
          delete correlation_ids.paypal_params;
        }

        logError(logErrorMessage, {
          reason: errorMessage && errorMessage[0],
          order,
          correlation_ids: correlation_ids,
          shipping_address: shipAddressParams,
        });

        analytics.track('CTA Clicked', {
          text: 'Place Order Button Selected - Unsuccessful',
          payment_method: paymentProvider,
          stripe_method_name: stripeMethodName,
          destination: CONFIRMATION_PATH,
          error_message: logErrorMessage,
          ...eventData,
        });

        displayError(errorMessage && errorMessage[0]);
        goToEdit();
      });
  };

  return (
    <TestTakerPaymentWrapper
      title="Review Your Order"
      paymentEnabled={true}
      showOrderSummary={true}
      onPlaceOrder={handlePlaceOrder}
    >
      <span data-track-custom-data={JSON.stringify({ ...eventData })} hidden />
      <S.ReviewOrderContainer>
        <div id="edit-link">
          <TertiaryLink onClick={goToEdit}>Edit</TertiaryLink>
        </div>

        <S.InfoContainer>
          <InfoBlock title="Shipping Address">
            <>
              {firstName && (
                <S.Text>
                  <LocalizeDisabled>{`${firstName} ${lastName}`}</LocalizeDisabled>
                </S.Text>
              )}
              {address && (
                <S.Text>
                  <LocalizeDisabled>{address}</LocalizeDisabled>
                </S.Text>
              )}
              {city && state && zipCode && (
                <S.Text>
                  <LocalizeDisabled>{`${city}, ${state} ${zipCode}`}</LocalizeDisabled>
                </S.Text>
              )}
            </>
          </InfoBlock>

          <InfoBlock title="Delivery Option">
            <S.DeliveryTitle>{selectedShippingMethod.title}</S.DeliveryTitle>
            <S.Text small={true}>{selectedShippingMethod.estimate}</S.Text>
          </InfoBlock>

          {paymentProvider && (
            <InfoBlock title="Payment Method">
              <S.PaymentContainer>
                <S.PaymentProvider provider={paymentProvider} />
                <S.Text provider={true}>
                  {lastDigits ? (
                    <PaymentProviderInfo
                      paymentProvider={paymentProvider}
                      lastDigits={lastDigits}
                    />
                  ) : (
                    <LocalizeDisabled>{email}</LocalizeDisabled>
                  )}
                </S.Text>
              </S.PaymentContainer>
            </InfoBlock>
          )}
        </S.InfoContainer>
      </S.ReviewOrderContainer>
    </TestTakerPaymentWrapper>
  );
};

export default ReviewOrderWrapper;
