import { typography, colors, size, H2, H4, Input } from '@everlywell/leaves';
import styled from 'styled-components';

const PageTitle = styled(H2)`
  width: 100%;
  margin-bottom: 0;
  text-align: center;
`;

const Subtitle = styled.div`
  ${typography.bodyTextSmall};
  color: ${colors.gray4};
  margin-bottom: ${size.xl1}px;
  text-align: center;
  padding: 0 5px;
`;

const InputField = styled(Input)`
  input[type='text'] {
    height: 48px;
    box-shadow: 4px 4px 15px -5px rgba(0, 0, 0, 0.1);
    border: solid 1px ${colors.green2};
    background-color: ${colors.white};

    &:disabled {
      -webkit-text-fill-color: ${colors.gray3};
    }
  }

  label {
    font-size: 1rem;
    font-weight: bold;
    line-height: 1.5;
    margin-bottom: ${size.xs3}px;
  }
`;

const ContactFormTitle = styled(H4)`
  margin-bottom: 0;
  margin-top: ${size.xl2}px;
`;

const ContactFormSubtitle = styled.div`
  ${typography.bodyTextSmall};
  margin-bottom: ${size.md}px;
`;

const ContactFormWrapper = styled.div`
  display: block;
`;

export {
  PageTitle,
  Subtitle,
  InputField,
  ContactFormWrapper,
  ContactFormTitle,
  ContactFormSubtitle,
};
