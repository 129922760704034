import { size, colors, mediaQueries } from '@everlywell/leaves';
import styled from 'styled-components';

const Container = styled.div`
  margin: ${size.sm}px 0;
  border-radius: 1px;
  background-color: ${colors.white};
  padding: 55px ${size.xl4}px 55px 63px;
  box-shadow: 0 2.5px 10px 0 rgba(189, 196, 197, 0.5);

  ${mediaQueries.forPhoneOnly} {
    padding: 45px ${size.lg}px ${size.lg}px;
  }
`;

export { Container };
