import {
  FUNDING,
  PayPalScriptProvider,
  usePayPalScriptReducer,
  PayPalButtons as SDKPayPalButtons,
} from '@paypal/react-paypal-js';
import { PayPalButtonsComponentProps } from "@paypal/react-paypal-js/dist/components/PayPalButtons";
import React from 'react';
// local imports
import { logError } from 'utils/helpers';

interface PayPalButtonsProps extends Pick<PayPalButtonsComponentProps,
  'onError'
  | 'fundingSource'
  | 'createOrder'
  | 'onApprove'
  | 'onShippingChange'
> {
  order: TestTakerOrder;
}

interface PayPalSectionProps {
  setValue: (name: string, newValue: unknown) => void;
  onSuccess: (arg: unknown, details: {
    payment_id: string;
    payer_id: string;
  }) => void;
  onPayPalError: (error: unknown) => void;
  order: TestTakerOrder;
}

import {
  purchaseUnitFromOrder,
  updateFormData,
  formatPayPalPaymentDetails,
} from './helpers';
import * as S from './styles';

const PayPalButtons = ({
  order,
  onError,
  onApprove,
  createOrder,
  fundingSource,
  onShippingChange,
}: PayPalButtonsProps) => {
  const [{ isRejected }] = usePayPalScriptReducer();

  const notifyRenderingError = () => {
    logError('Opt-In - Taker paid - PayPal Button rendering failed', {
      order: order,
    });
  };

  return (
    <>
      {isRejected && notifyRenderingError()}

      <SDKPayPalButtons
        onError={onError}
        onApprove={onApprove}
        createOrder={createOrder}
        fundingSource={fundingSource}
        onShippingChange={onShippingChange}
        style={{
          height: 48,
          label: 'checkout',
          shape: 'rect'
        }}
      />
    </>
  );
};

export const PayPalSection = ({
  setValue,
  onSuccess,
  onPayPalError,
  order: spreeOrder,
}: PayPalSectionProps) => {
  /**
   * Function called after the PayPal button is clicked and the user has
   * logged in.
   *
   * @param _ provided by PayPal
   * @param actions provided by PayPal
   * @returns
   */
  const createOrder: PayPalButtonsComponentProps['createOrder'] = async (_, actions) => {
    const purchase_units = purchaseUnitFromOrder(spreeOrder);
    return await actions.order.create({
      purchase_units,
    });
  };

  /**
   * Function called after the user has successfully "submitted" their info via paypal
   * @param _ provided by PayPal
   * @param actions provided by PayPal
   */
  const onApprove: PayPalButtonsComponentProps['onApprove'] = async (data, actions) => {
    try {
      const orderData = await actions?.order.capture();
      const paypalDetails = formatPayPalPaymentDetails(orderData);
      updateFormData(orderData, setValue);
      onSuccess(null, paypalDetails);
    } catch (err) {
      onPayPalError(err);
    }
  };

  /**
   * Function called after an error occurs while processing PayPal payment
   */
  const onError = () => {
    logError('Opt-In - Taker paid - PayPal payment failed', {
      order: spreeOrder,
    });
  };

  return (
    <S.PayPalButtonsContainer
      data-testid={`paypal-checkout-button`}
      xs={12}
      sm={6}
    >
      <PayPalScriptProvider
        options={{
          'client-id': process.env.GATSBY_PAYPAL_CLIENT_ID || 'sb',
          commit: false,
          debug: process.env.PAYPAL_DEBUG || false,
        }}
      >
        <PayPalButtons
          onError={onError}
          order={spreeOrder}
          onApprove={onApprove}
          createOrder={createOrder}
          fundingSource={FUNDING.PAYPAL}
        />
      </PayPalScriptProvider>
    </S.PayPalButtonsContainer>
  );
};

export default PayPalSection;
