import { Row, FormOption } from '@everlywell/leaves';
import React, { useState } from 'react';
import { UseFormMethods } from 'react-hook-form';

// local imports
import * as S from './styles';

interface Props {
  options: ShippingMethod[];
  selectedOption?: string;
  standardOnly?: boolean;
  register: UseFormMethods["register"];
  setValue: UseFormMethods["setValue"];
  onChange?: (option: ShippingMethod) => void;
}

const DeliveryOptionsField = ({
  options = [],
  selectedOption,
  standardOnly = true,
  register,
  setValue,
  onChange,
}: Props) => {
  const deliveryOptions: ShippingMethod[] = standardOnly
    ? [options.find((option) => option.name === 'standard') as ShippingMethod]
    : options;

  const initalSelection = () => {
    const foundOption = options.find((option) => option.value === selectedOption);

    return foundOption ? selectedOption : deliveryOptions[0]?.value;
  };

  const [selected, setSelected] = useState(initalSelection);

  const selectOptionById = (id: number) => {
    return deliveryOptions.find((option) => option?.id === id);
  };

  const handleDeliveryChange = (id: string) => {
    const option = selectOptionById(Number(id));
    const value = option?.value || '';

    setSelected(value);
    setValue('shippingType', value);

    (onChange && option) && onChange(option);
  };

  const formatPrice = (price: number) => {
    return price <= 0 ? 'Free' : `$${price}`;
  };

  return (
    <>
      <input
        type="hidden"
        id="shippingType"
        name="shippingType"
        value={selected}
        ref={register()}
        data-testid="shippingType"
      />

      <Row>
        <S.OptionsWrapper>
          {deliveryOptions.map((option, index) => (
            <FormOption
              key={option.name}
              id={String(option.id)}
              name={option.name}
              value={option.value}
              label={{
                label: option.title,
                subLabel: formatPrice(option.price),
                labelDescription: option.estimate,
              }}
              checked={selected === option.value}
              onChange={handleDeliveryChange}
              displaySeparator={index !== deliveryOptions.length - 1}
            />
          ))}
        </S.OptionsWrapper>
      </Row>
    </>
  );
}

export default DeliveryOptionsField;
