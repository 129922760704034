import { Products, Localize } from '@everlywell/leaves';
import { useLocation } from '@reach/router';
import apiHealthPlanInformation from 'api/health-plan-information';
import orderCreate from 'api/test-taker-paid/order-create';
import { useProductsList } from 'components/forms/QuantityDropdown/hooks';
import { navigate } from 'gatsby';
import { useFindManyProducts, useLineItems } from 'hooks';
import React from 'react';
// local imports
import { ERROR_TITLE, ERROR_CONTENT } from 'utils/constants';
import {
  analytics,
  logError,
  displayError,
  sanitize,
  truncate,
  formatDateOfBirth,
} from 'utils/helpers';
import { getUser, setInnerNavigate } from 'utils/services/auth';

import TestTakerPaymentWrapper from '../index';
import * as S from './styles';

interface LocationState {
  firstName: string;
  lastName: string;
  contentfulProductIds: number[];
  contentfulProductIdToPrice: {
    [productId: string]: number;
  };
  kitSelectionEnabled: boolean;
  allowedQuantityPerProduct: {
    [productId: string]: number;
  }[];
  enterpriseClientId: number;
  email: string;
  thirdPartyId?: string;
  dob: string;
  state: string;
  tenantName: string;
  isQuotaFull: boolean;
}

const ProductsWrapper = () => {
  const location = useLocation();
  const locationState = location?.state as LocationState;

  const userName = locationState.firstName;
  const userLastName = locationState.lastName;
  const contentfulProductIds = locationState.contentfulProductIds || [];
  const contentfulProductIdToPrice = locationState.contentfulProductIdToPrice || {};

  const products = useFindManyProducts({
    productIds: contentfulProductIds,
  }).map((contentfulProduct) => ({
    ...contentfulProduct,
    price: contentfulProductIdToPrice[contentfulProduct.productId],
  }));

  const kitSelectionEnabled = locationState?.kitSelectionEnabled;
  const allowedQuantityPerProduct = locationState?.allowedQuantityPerProduct;

  const { lineItems } = useLineItems();
  const productsList = useProductsList(
    products,
    '',
    kitSelectionEnabled,
    allowedQuantityPerProduct,
  );

  const getPayload = () => {
    return {
      order: {
        access_code: getUser().accessCode,
        enterprise_partner_id: process.env.GATSBY_ENTERPRISE_PARTNER_ID,
        enterprise_client_id: locationState?.enterpriseClientId,
        email: locationState?.email,
        third_party_member_id: locationState?.thirdPartyId,
        user: { dob: formatDateOfBirth(locationState?.dob) },
        line_items: lineItems,
        ship_address: { state: locationState?.state, country: 'US' },
      },
    };
  };

  const onSubmit = async (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setInnerNavigate();

    let payload = getPayload();
    const tenantName = locationState?.tenantName;
    const healthPlanCheckEnable =
      process.env.GATSBY_SHOULD_CHECK_HEALTH_PLAN_INFORMATION === 'true';
    const hasTenant = !(tenantName === null || tenantName === '');
    const shouldCheckHealthPlanInfo = healthPlanCheckEnable && hasTenant;
    const productIdForHealthPlanInformationReference =
      payload?.order?.line_items[0].product_id;

    let coBrand: string | null, programName: string | null, preferredLanguage: string | null;

    const getHealthPlanInformation = async () => {
      try {
        const ApiResponse = await apiHealthPlanInformation(
          {
            member_id: payload?.order?.third_party_member_id,
            dob: formatDateOfBirth(payload?.order?.user?.dob),
            last_name: userLastName,
            ew_product_id: productIdForHealthPlanInformationReference,
          },
          tenantName,
        );

        return ApiResponse;
      } catch (_error) {
        displayError(
          ERROR_TITLE.HEALTH_PLAN_INFORMATION_ERROR,
          ERROR_CONTENT.HEALTH_PLAN_INFORMATION_ERROR,
        );
        navigate('/app/payment-steps/personal-info', {
          state: locationState,
        });
        return;
      }
    };

    let shouldPlaceOrder = true;

    if (shouldCheckHealthPlanInfo) {
      shouldPlaceOrder = false;

      const response = await getHealthPlanInformation();

      if (response === null || response === undefined) {
        return;
      }

      programName = response.data.data.program_name;

      if (programName === null || programName === '') {
        displayError(
          ERROR_TITLE.HEALTH_PLAN_INFORMATION_ERROR,
          ERROR_CONTENT.HEALTH_PLAN_INFORMATION_ERROR,
        );
        navigate('/app/payment-steps/personal-info', {
          state: locationState,
        });
        return;
      } else {
        shouldPlaceOrder = true;
        coBrand = response.data.data.co_brand;
        preferredLanguage = response.data.data.preferred_language;
      }
    }

    if (shouldPlaceOrder) {
      orderCreate(payload)
        .then((response) => {
          window.localStorage.setItem('jwt', response.headers.authorization);

          navigate('/app/payment-steps/payment', {
            state: Object.assign(
              { products: products, spreeOrder: response.data },
              locationState,
              { lineItems },
              { coBrand, programName, preferredLanguage },
            ),
          });
        })
        .catch((error) => {
          const errorMessages = error?.response?.data?.errors;

          logError('Opt-In - Taker paid - Order creation failed', {
            reason: errorMessages && errorMessages[0],
            correlation_ids: {
              contentful_product_ids: contentfulProductIds,
              third_party_id: locationState?.thirdPartyId,
            },
            personal_information: {
              dob: locationState?.dob,
              email: locationState?.email,
              state: locationState?.state,
              firstname: locationState?.firstName,
              lastname: locationState?.lastName,
            },
          });

          if (errorMessages && errorMessages.length > 0) {
            displayError(
              `There was an issue creating your order: ${errorMessages[0]}`,
            );
            navigate('/app/payment-steps/personal-info', {
              state: locationState,
            });
            return;
          }

          displayError(
            'There was an issue creating your order. Please reach out to your administrator.',
          );
          // eslint-disable-next-line no-console
          console.error(error);
          navigate('/app/payment-steps/personal-info', {
            state: locationState,
          });
        });
    }

    analytics.track('CTA Clicked', {
      page_name: 'Test Taker Paid - Available Tests',
      test_type: products.map((product) => product.displayName),
      test_id: products.map((product) => product.productId),
      test_quantity: products.length,
    });
  };

  const onCancel = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();

    analytics.track('CTA Clicked', {
      text: 'Cancel',
      type: 'Button',
    });

    navigate('/');
  };

  return (
    <TestTakerPaymentWrapper title="Available Tests" showOrderSummary={true}>
      <S.ProductsContainer>
        {userName && (
          <S.ProductsTitle>
            Welcome, <Localize name="first-name">{userName}</Localize>!
          </S.ProductsTitle>
        )}
        <S.ProductsText>
          With Everlywell, you can access lab tests from the comfort of your
          home.
        </S.ProductsText>

        <Products
          userName={userName}
          /*
            Leaves Product component has optional props that are typed as required,
            and props that are no longer being used.

            TODO: On Leaves, remove imageURL prop, and make price prop optional.
          */
          // @ts-ignore
          productsList={productsList}
          onClick={onSubmit}
          onCancel={onCancel}
          truncationStrategy={truncate}
          sanitationStrategy={sanitize}
          isQuotaFull={locationState?.isQuotaFull}
        />
      </S.ProductsContainer>
    </TestTakerPaymentWrapper>
  );
};

export default ProductsWrapper;
