import React from 'react';

import QuantityDropdown from '.';

type AllowedQuantityPerProductType = {
  [key: string]: number
};

const checkProductsAvailability = (productIds: number[], allowedQuantityPerProduct: AllowedQuantityPerProductType[]) => {
  // Convert `allowedQuantityPerProduct` into an object
  const parsedAllowedQuantityPerProduct = allowedQuantityPerProduct.reduce(
    (acc, curr) => ({ ...acc, ...curr }),
    {},
  );

  // Check if all products can be ordered
  const allProductsAvailable = productIds.every(
    (id) => parsedAllowedQuantityPerProduct[id] > 0,
  );

  return { allProductsAvailable, parsedAllowedQuantityPerProduct };
};

const addQuantityDropdown = (
  product: Product,
  productId: number,
  allowedQuantityPerProduct: AllowedQuantityPerProductType,
  source: string,
) => {
  const allowedQuantity = Number(allowedQuantityPerProduct[productId]);

  let options = [];

  for (let i = 0; i < allowedQuantity; i++) {
    options.push({ value: String(i + 1), id: String(i) });
  }

  return {
    ...product,
    detailsComponent: (
      <QuantityDropdown
        productId={productId}
        options={options}
        source={source}
      />
    ),
  };
};

/*
  TODO: Refactor Leaves Product component to expect only image source

  With the update of Gatsby, the fluid attribute inside `productCardImage` that was expected to be present
  in a Gatsby image doesn't exist anymore, which is causing issues with Product component
  that was using this attribute to display product image, we need to refactor the component
  to expect an image source instead of an attribute `productCardImage`.

  https://gitlab.com/everlywell/apps/leaves/-/blob/main/src/components/Products/Product/index.tsx#L26
*/
const normalizeProduct = (product: Product) => {
  return {
    ...product,
    productCardImage: {
      ...product?.productCardImage,
      fluid: {
        src: product?.productCardImage?.gatsbyImageData?.images?.fallback?.src,
      },
    },
  };
};

export { checkProductsAvailability, addQuantityDropdown, normalizeProduct };
