import { H4, size, mediaQueries, colors, typography } from '@everlywell/leaves';
import styled from 'styled-components';

const ProductsContainer = styled.div`
  border-radius: 1px;
  margin: ${size.sm}px 0;
  padding: 56px 64.8px 41px 63px;
  background-color: ${colors.white};
  box-shadow: 0 2.5px 10px 0 rgba(189, 196, 197, 0.5);

  ${mediaQueries.forPhoneOnly} {
    padding: 45px ${size.lg}px ${size.lg}px;
  }
`;

const ProductsTitle = styled(H4)`
  font-size: 28.8px;
  letter-spacing: 0.63px;
  line-height: 1.11;
  margin: 0 7px ${size.sm}px 0;
  word-break: break-word;
`;

const ProductsText = styled.p`
  font-size: 18px;
  line-height: 1.78;
  color: ${colors.gray4};
  font-weight: ${typography.weight.book};
`;

export { ProductsContainer, ProductsTitle, ProductsText };
