import React from 'react';

// local imports
import * as S from './styles';

export const AcceptedPayments = () => {
  return (
    <S.AcceptedCards sm md>
      <S.CreditCardProviders>
        <S.MasterCard />
        <S.Visa />
        <S.AmericanExpress />
        <S.Discover />
      </S.CreditCardProviders>
      <S.Note>We accept HSA/FSA payments</S.Note>
    </S.AcceptedCards>
  );
};

export default AcceptedPayments;
