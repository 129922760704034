import {
  Button,
  Input,
  Container,
  colors,
  mediaQueries,
  Row,
  size,
  typography,
} from '@everlywell/leaves';
import { BaseButton } from 'components/ui';
import styled from 'styled-components';

const ShippingPaymentContainer = styled(Container)`
  border-radius: 1px;
  margin: ${size.sm}px 0;
  padding: 55px 60px 44px 64px;
  box-shadow: 0 2.5px 10px 0 rgba(189, 196, 197, 0.5);
  background-color: ${colors.white};

  ${mediaQueries.forPhoneOnly} {
    padding: 45px ${size.lg}px ${size.lg}px;
  }
`;

const Section = styled.div<{ noBottom?: boolean; }>`
  margin-bottom: ${(props) => (props.noBottom ? '0' : `${size.xl3}px`)};
`;

const SectionHeading = styled.h4`
  ${typography.h4Text};
  margin-bottom: ${size.lg}px;
`;

const ButtonSection = styled(Row)`
  margin-bottom: 42px;
`;

const ButtonGroup = styled(Row)`
  margin-top: ${size.xl3}px;

  ${mediaQueries.forPhoneOnly} {
    margin-top: ${size.lg}px;
  }
`;

const PrimaryButton = styled(BaseButton)`
  width: 150px;
  height: ${size.xl3}px;
  margin: 0 10px 0 ${size.md}px;

  ${mediaQueries.forPhoneOnly} {
    width: 100%;
    margin-left: 10px;
    justify-content: center;
  }
`;

const SecondaryButton = styled(Button)`
  gap: 10px;
  width: 150px;
  height: ${size.xl3}px;
  margin: 0 10px;

  ${mediaQueries.forPhoneOnly} {
    width: 100%;
    margin-top: ${size.md}px;
  }
`;

const Hidden = styled(Input)`
  display: none;
`;

export {
  ButtonGroup,
  ButtonSection,
  PrimaryButton,
  Section,
  SectionHeading,
  SecondaryButton,
  ShippingPaymentContainer,
  Hidden,
};
