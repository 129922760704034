import axios from 'axios';

/**
 *
 * @param {Object} payload payload for the order placement
 *
 *  {
      "email": "example@everlywell.com",
      "line_items": [
        {
        "product_id": 88,
        "quantity": 1
        }
      ],
      "user_id": 1, <-- Enterprise Partner Id
      "enterprise_client_id": 1,
      "third_party_member_id": "X9342AD",
      "auto_register": false
    }
 */

/*
Just pasting this here for now as an example of usage:
const payload = {
  enterprise_partner_id: process.env.GATSBY_ENTERPRISE_PARTNER_ID,
  enterprise_client_id,
  access_code: "TEST-5",

  email: "jeronmparaiso6a@gmail.com",

  line_items: product_ids.map(id => ({ product_id: id, quantity: 1 })),

  ship_address: {
    firstname: "Jeron",
    lastname: "Paraiso",
    address1: "1343 BB Lane",
    address2: "",
    city: "Brisbane",
    state: "Chaos",
    zipcode: "22719",
    country: 'US',
    phone: "6191234556",
  },
  user_id: '',
  third_party_member_id: 'foo',
  auto_register: false,

  user: {
    dob: '1985-11-10',
  },
};
orderCreate(payload).then((r) => {
  window.localStorage.setItem('jwt', r.headers.authorization)
  orderFetch(r.data.number)
})
 */

const orderCreate = payload => {
  return axios.post(
    process.env.GATSBY_TEST_TAKER_PAID_ORDER_CREATE_ENDPOINT,
    payload,
  );
};

export default orderCreate;
