import { LocalizeDisabled } from '@everlywell/leaves';
import apiHealthPlanInformation from 'api/health-plan-information';
import apiOrder from 'api/order-placement';
import { WizardContext } from 'contexts/wizard';
import { useFindManyProducts } from 'hooks';
import React, { useContext, useEffect, useState } from 'react';
import { MainContainerWrapper } from 'styles/global';
import { ERROR_TITLE, ERROR_CONTENT } from 'utils/constants';
import {
  analytics,
  displayError,
  trim,
  parseErrorsMessage,
  normalizePhone,
  formatDateOfBirth,
} from 'utils/helpers';
import { getUser } from 'utils/services/auth';

import { PAGE_INFO } from '../constants';
import { ContinueButtonWrapper, Button } from '../styles';
import * as S from './styles';

interface Props {
  products: Product[];
  moveForward: () => void;
  goToPreviousStep: () => void;
  currentStep: 0 | 1 | 2 | 3;
}

interface Payload {
  enterprise_partner_id: string;
  enterprise_client_id: number | null;
  access_code: string;
  email: string;
  line_items: LineItem[];
  ship_address: {
    firstname: string;
    lastname: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zipcode: string;
    country: 'US',
    phone: string | null;
  },
  shipping_method_id: number | null;

  user_id: '',
  third_party_member_id: string | null;
  auto_register: boolean;
  user: {
    dob: string;
    gender?: string;
  },
  co_brand?: string;
  enterprise_program_name?: string;
  member_preferred_language?: string;
}

const getAddress = (address1: string, address2: string) => {
  let address = '';
  if (address1) {
    address = address1;
    if (address2) {
      address += `, ${address2}`;
    }
  } else {
    address = address2;
  }
  return address;
};

const ReviewStep = ({
  products,
  moveForward,
  goToPreviousStep,
  currentStep,
}: Props) => {
  const [isOrderBeingPlaced, setIsOrderBeingPlaced] = useState(false);

  const {
    // Personal Information step
    firstName,
    lastName,
    biologicalSex,
    userId,
    userIdLabel,
    birthday,

    // Shipping Information step
    name,
    address1,
    address2,
    city,
    state,
    zipcode,
    email,
    phone,
    shippingId,
    deliveryOptions,

    // Enterprise Client Info
    enterpriseClientId,

    // Products
    lineItems,

    // Auto Register
    autoRegisterOrders,

    setWizard,

    tenantName,
    contentfulProductIds,
  } = useContext(WizardContext);

  const eventData = {
    page_name: PAGE_INFO[currentStep].name,
  };

  useEffect(() => {
    analytics.track('Page Viewed', {
      ...eventData,
      label: 'Review Order Page - Access Code OiP',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const foundProducts = useFindManyProducts({
    productIds: contentfulProductIds,
  });

  if (process.env.GATSBY_ACCESS_CODE_ELIGIBLITY_CHECK_ENABLED === 'true') {
    products = foundProducts;
  }

  const address = getAddress(address1, address2);

  const deliveryOption = deliveryOptions.find(
    (option) => option.id === shippingId,
  );

  const handleError = (error: { response: { data: { errors: string[]; }; }; }) => {
    const errors = Array.from(error?.response?.data?.errors || []);
    const [title, content] = parseErrorsMessage(errors);

    return displayError(title, content);
  };

  const productIdForHealthPlanInformationReference = lineItems[0].product_id;

  const healthPlanCheckEnable =
    process.env.GATSBY_SHOULD_CHECK_HEALTH_PLAN_INFORMATION === 'true';
  const hasTenant = !(tenantName === null || tenantName === '');
  const shouldCheckHealthPlanInfo = healthPlanCheckEnable && hasTenant;

  const handleOrder = async () => {
    if (isOrderBeingPlaced) return;

    setIsOrderBeingPlaced(true);

    const payload: Payload = {
      enterprise_partner_id: process.env.GATSBY_ENTERPRISE_PARTNER_ID!,
      enterprise_client_id: enterpriseClientId,
      access_code: getUser().accessCode,

      email,

      line_items: lineItems,

      ship_address: {
        firstname: trim(firstName),
        lastname: trim(lastName),
        address1: trim(address1),
        address2: trim(address2),
        city: trim(city),
        state: trim(state),
        zipcode: trim(zipcode),
        country: 'US',
        phone,
      },

      shipping_method_id: shippingId,

      user_id: '',
      third_party_member_id: userId,
      auto_register: autoRegisterOrders,

      user: {
        dob: formatDateOfBirth(birthday),
        ...(autoRegisterOrders && { gender: biologicalSex }),
      },
    };

    const getHealthPlanInformation = async () => {
      try {
        const response = await apiHealthPlanInformation(
          {
            member_id: userId,
            dob: formatDateOfBirth(birthday),
            last_name: lastName,
            ew_product_id: productIdForHealthPlanInformationReference,
          },
          tenantName,
        );

        return response;
      } catch (error) {
        setIsOrderBeingPlaced(false);
        let message = null;

        if (error instanceof Error) {
          message = error.message;
        } else {
          console.error('Unexpected error', error);
        }

        analytics.track('CTA Clicked', {
          ...eventData,
          text: 'Place Order Button Selected - Unsuccessful',
          error_message: message,
        });

        return displayError(
          ERROR_TITLE.HEALTH_PLAN_INFORMATION_ERROR,
          ERROR_CONTENT.HEALTH_PLAN_INFORMATION_ERROR,
        );
      }
    };

    let shouldPlaceOrder = true;

    if (shouldCheckHealthPlanInfo) {
      shouldPlaceOrder = false;

      const response = await getHealthPlanInformation();

      if (response === null || response === undefined) {
        setIsOrderBeingPlaced(false);
        return;
      }

      const coBrand = response.data.data.co_brand;
      const programName = response.data.data.program_name;
      const preferredLanguage = response.data.data.preferred_language;

      if (programName === null || programName === '') {
        setIsOrderBeingPlaced(false);

        analytics.track('CTA Clicked', {
          ...eventData,
          text: 'Place Order Button Selected - Unsuccessful',
          error_message: ERROR_TITLE.PROGRAM_NAME_NOT_FOUND,
        });

        return displayError(
          ERROR_TITLE.HEALTH_PLAN_INFORMATION_ERROR,
          ERROR_CONTENT.HEALTH_PLAN_INFORMATION_ERROR,
        );
      } else {
        payload.co_brand = coBrand;
        payload.enterprise_program_name = programName;
        payload.member_preferred_language = preferredLanguage;
        shouldPlaceOrder = true;
      }
    }

    if (shouldPlaceOrder) {
      apiOrder(payload)
        .then((response) => {
          if (response?.data?.number) {
            setWizard({
              orderNumber: response.data.number,
              orderEmail: response.data.email,
            });

            analytics.track('CTA Clicked', {
              ...eventData,
              text: 'Place Order Button Selected',
            });

            moveForward();
          }
        })
        .catch((error) => {
          analytics.track('CTA Clicked', {
            ...eventData,
            text: 'Place Order Button Selected - Unsuccessful',
            error_message: error?.response?.data?.errors?.[0],
          });

          handleError(error);
        })
        .finally(() => {
          setIsOrderBeingPlaced(false);
        });
    }
  };

  const handleChangeClick = () => {
    analytics.track('CTA Clicked', {
      ...eventData,
      text: 'Shipping Information "Change" Button',
    });
    goToPreviousStep();
  };

  return (
    <MainContainerWrapper>
      <S.Title>Review Order</S.Title>

      <S.ReviewWrapper>
        {/* Personal Information */}
        <S.H2>Personal Information</S.H2>
        <S.Row>
          <S.Label>Name</S.Label>
          <S.LabelText>
            <LocalizeDisabled>{`${firstName} ${lastName}`}</LocalizeDisabled>
          </S.LabelText>
        </S.Row>
        {autoRegisterOrders && (
          <S.Row>
            <S.Label>Sex Assigned At Birth</S.Label>
            <S.LabelText
              style={{
                textTransform: 'capitalize',
                display: 'inline-block',
              }}
            >
              {biologicalSex}
            </S.LabelText>
          </S.Row>
        )}
        <S.Row>
          <S.Label>Date of Birth</S.Label>
          <S.LabelText>
            <LocalizeDisabled>{birthday}</LocalizeDisabled>
          </S.LabelText>
        </S.Row>
        <S.Row>
          <S.Label>Email</S.Label>
          <S.LabelText>
            <LocalizeDisabled>{email}</LocalizeDisabled>
          </S.LabelText>
        </S.Row>
        {userId && (
          <S.Row>
            <S.Label>{userIdLabel}</S.Label>
            <S.LabelText>
              <LocalizeDisabled>{userId}</LocalizeDisabled>
            </S.LabelText>
          </S.Row>
        )}
        {phone && (
          <S.Row>
            <S.Label>Phone</S.Label>
            <S.LabelText>
              <LocalizeDisabled>{normalizePhone(phone)}</LocalizeDisabled>
            </S.LabelText>
          </S.Row>
        )}

        {/* Items in the order */}
        <S.H2>Items in the order</S.H2>
        {products.map((product, index) => (
          <S.Row key={index}>
            <S.Text>{product?.displayName}</S.Text>
          </S.Row>
        ))}

        {/* Ships to */}
        <S.LinkWrapper>
          <S.ShipsH2>Ships to</S.ShipsH2>
          <S.ChangeLink onClick={handleChangeClick}>Change</S.ChangeLink>
        </S.LinkWrapper>
        <S.Row>
          <S.Text>
            <LocalizeDisabled>{name}</LocalizeDisabled>
          </S.Text>
        </S.Row>
        <S.Row>
          <S.Text>
            <LocalizeDisabled>{address}</LocalizeDisabled>
          </S.Text>
        </S.Row>
        <S.Row>
          <S.Text>
            <LocalizeDisabled>
              {city}, {state} {zipcode}
            </LocalizeDisabled>
          </S.Text>
        </S.Row>

        <S.DeliveryRow>
          <S.Label>Delivery</S.Label>
          <S.LabelText>{deliveryOption?.name}</S.LabelText>
        </S.DeliveryRow>
      </S.ReviewWrapper>
      <ContinueButtonWrapper>
        <Button
          onClick={handleOrder}
          isDisabled={isOrderBeingPlaced}
          isLoading={isOrderBeingPlaced}
        >
          Place Order
        </Button>
      </ContinueButtonWrapper>
    </MainContainerWrapper>
  );
};

export default ReviewStep;
