import { colors, size } from '@everlywell/leaves';
import { Router } from '@reach/router';
import { Layout } from 'components/access-code/common';
import OrderPlaced from 'components/access-code/pages/order-placed';
import ProductsWrapper from 'components/access-code/pages/test-taker-payment/available-tests';
import PaymentWrapper from 'components/access-code/pages/test-taker-payment/payment';
import PersonalInformationWrapper from 'components/access-code/pages/test-taker-payment/personal-info';
import ReviewOrderWrapper from 'components/access-code/pages/test-taker-payment/review-order';
import SuccessWrapper from 'components/access-code/pages/test-taker-payment/success';
import Wizard from 'components/access-code/pages/wizard';
import { PrivateRoute, PrivateHead } from 'components/common';
import IconClose from 'components/icons/modal-close';
import { TestTakerPaymentProvider } from 'contexts/test-taker-payment';
import { ThemeContextProvider } from 'contexts/theme-context';
import { WizardProvider } from 'contexts/wizard';
import React from 'react';
import { ToastContainer, Slide } from 'react-toastify';
import styled from 'styled-components';

import 'react-toastify/dist/ReactToastify.css';
const StyledToastContainer = styled(ToastContainer)`
  top: 7em;
  transform: unset;
  left: calc(50% - 297px);

  .Toastify__toast {
    width: 594px;
    padding: ${size.sm}px;
    border-radius: 1px;
    box-shadow: 0 2px 20px -5px rgba(170, 169, 172, 0.4);
    background-color: white;

    @media (max-width: 786px) {
      width: 343px;
      left: calc(100% - 60%);
    }
  }

  .Toastify__toast--error {
    background-color: ${colors.red1};
  }

  .Toastify__progress-bar--error {
    background-color: ${colors.red3};
  }
`;

export const Head = ({ location }) => <PrivateHead location={location} />;

const App = () => {
  return (
    <ThemeContextProvider>
      <Layout>
        <StyledToastContainer
          autoClose={8000}
          transition={Slide}
          closeButton={IconClose}
        />
        <WizardProvider>
          <WizardRoutes />
        </WizardProvider>

        <TestTakerPaymentProvider>
          <TestTakerRoutes />
        </TestTakerPaymentProvider>
      </Layout>
    </ThemeContextProvider>
  );
};
const WizardRoutes = () => (
  <Router>
    <PrivateRoute
      path="/app/wizard"
      analyticsPageName="Enterprise Paid - Wizard"
      component={Wizard}
    />
    <PrivateRoute
      path="/app/order-placed"
      component={OrderPlaced}
      analyticsPageName="Enterprise Paid - Order Placed"
    />
  </Router>
);

const TestTakerRoutes = () => (
  <Router>
    <PrivateRoute
      path="/app/payment-steps/personal-info"
      analyticsPageName="Test Taker Paid - Personal Info"
      component={PersonalInformationWrapper}
    />
    <PrivateRoute
      path="/app/payment-steps/available-tests"
      analyticsPageName="Test Taker Paid - Available Tests"
      component={ProductsWrapper}
    />
    <PrivateRoute
      path="/app/payment-steps/payment"
      analyticsPageName="Test Taker Paid - Shipping and Payment"
      component={PaymentWrapper}
    />
    <PrivateRoute
      path="/app/payment-steps/review-order"
      analyticsPageName="Test Taker Paid - Order Review"
      component={ReviewOrderWrapper}
    />
    <PrivateRoute
      path="/app/payment-steps/success"
      analyticsPageName="Test Taker Paid - Order Confirmation"
      component={SuccessWrapper}
    />
  </Router>
);

export default App;
