import React from 'react';
import { OVERNIGHT_SHIPPING, STANDARD_SHIPPING } from 'utils/constants';
import { deliveryOptionFormat } from 'utils/helpers';
import { UseFormMethods } from 'react-hook-form';

import DeliveryOptionsField from './DeliveryOptionsField';
import * as S from './styles';

interface Props {
  register: UseFormMethods["register"];
  setValue: UseFormMethods["setValue"];
  onChange: (option: ShippingMethod) => void;
  deliveryOptions: DeliveryOption[];
  selectedOption?: number;
  disableOvernightShipping: boolean;
}

const DeliveryOptionsSelector = ({
  register,
  setValue,
  onChange,
  deliveryOptions,
  selectedOption,
  disableOvernightShipping,
}: Props) => {
  const standardShipping = deliveryOptions.find(
    (option) => option.id === STANDARD_SHIPPING,
  );

  const filteredDeliveryOptions = deliveryOptions.filter((option) => {
    if (disableOvernightShipping && option.id === OVERNIGHT_SHIPPING) {
      return false;
    }

    return true;
  });

  const formattedDeliveryOptions = filteredDeliveryOptions.map((option) =>
    deliveryOptionFormat(option),
  );

  const standardOnly =
    filteredDeliveryOptions.length === 1 && !!standardShipping;

  return (
    <S.Wrapper>
      <S.Title>Delivery Options</S.Title>
      <S.Description>
        Select delivery option for your test kit order. Overnight shipping from
        you to the lab is included, regardless of your selection.
      </S.Description>

      <DeliveryOptionsField
        register={register}
        setValue={setValue}
        onChange={onChange}
        standardOnly={standardOnly}
        options={formattedDeliveryOptions}
        selectedOption={selectedOption?.toString()}
      />
    </S.Wrapper>
  );
};

export default DeliveryOptionsSelector;
