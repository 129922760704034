import { useLocation } from '@reach/router';

import {
  checkProductsAvailability,
  addQuantityDropdown,
  normalizeProduct,
} from './helpers';

const useProductsList = (
  products: Product[],
  source: string,
  kitSelectionEnabled: boolean,
  allowedQuantityPerProduct: EnterpriseWizardContext["allowedQuantityPerProduct"] = [],
) => {
  const { state } = useLocation();
  const productIds = (state as Partial<{ productIds: number[] }>)?.productIds || [];

  if (!kitSelectionEnabled || allowedQuantityPerProduct?.length === 0)
    return products.map(normalizeProduct);

  const { allProductsAvailable, parsedAllowedQuantityPerProduct } =
    checkProductsAvailability(productIds, allowedQuantityPerProduct!);

  if (!allProductsAvailable) return [];

  return products.map((product, index) => {
    const productId = productIds[index];

    return addQuantityDropdown(
      normalizeProduct(product),
      productId,
      parsedAllowedQuantityPerProduct,
      source,
    );
  });
};

export { useProductsList };
