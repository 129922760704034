import { sum } from 'lodash-es';

interface FormDataParams {
  payer: {
    email_address: string;
    name: {
      given_name: string;
      surname: string;
    };
    phone?: {
      phone_number: string;
    };
    address: {
      country_code: string;
    };
  };
  purchase_units: {
    shipping?: {
      address: {
        postal_code: string;
        admin_area_1: string;
        admin_area_2: string;
        address_line_1: string;
        address_line_2?: string;
      };
    };
  }[];
}

type SetValueType = (name: string, newValue: unknown) => void;

/**
 * Helper function for referring to USD-dollars
 *
 * @param value
 * @param currency_code
 * @returns an Amount object for use in the PayPal API
 */
export const USD = (value: string | number, currency_code = 'USD') => {
  return {
    value: `${(+value).toFixed(2)}`,
    currency_code,
  };
};

export const Zero = '0.00';
export const Free = '0.00';

/**
 * Formats serialized Spree::Order as a PurchaseUnit for PayPal.
 * We only allow single PUs, but PayPal expects an array, so
 * we return an array with a single PU in it.
 *
 * @param order the current order
 * @returns a paypal PurchaseItem, which is like an order chunk
 */
export const purchaseUnitFromOrder = (order: { number: string; }) => {
  const {
    orderTotal,
    item_total,
    tax_total,
    discount,
    shipping,
    handling,
    insurance,
    shipping_discount,
  } = calculatePaypalOrderTotals(order);

  return [
    {
      amount: {
        ...USD(orderTotal),
        breakdown: {
          item_total: USD(item_total),
          tax_total: USD(tax_total),
          discount: USD(discount),
          shipping: USD(shipping),
          handling: USD(handling),
          insurance: USD(insurance),
          shipping_discount: USD(shipping_discount),
        },
      },
      custom_id: `${order.number}`,
    },
  ];
};

/**
 * Function processes Order prices (totals, tax, promos, etc.) and sums
 * them as PayPal defines them, also providing proper formatting
 *
 * @param order : the Spree::Order;
 * @returns order amounts for key items formatted for PayPal
 */
export const calculatePaypalOrderTotals = (order: { number?: string; total?: number; shipping?: number; }) => {
  const item_total = order?.total ?? 0;
  const tax_total = 0;
  // Spree returns `discount` as a negative number and we
  // want it that way so we can use `sum` here. When we return the value,
  // we need to format it as a posative number for PayPal.
  const discount = 0;
  const shipping = order?.shipping ?? 0;
  const handling = 0;
  const insurance = 0;
  const shipping_discount = 0; // This should be negative when not Zero, but returned positive for PayPal
  const orderTotal = sum([
    item_total,
    tax_total,
    discount,
    shipping,
    handling,
    insurance,
    shipping_discount,
  ]);
  return {
    orderTotal,
    item_total,
    tax_total,
    discount: -discount, // See note above
    shipping,
    handling,
    insurance,
    shipping_discount: -shipping_discount,
  };
};

/**
 * Update the form data based on the PayPal Order API response
 *
 * @param data The response body from PayPal on successful order creation.
 * @returns void
 */
export const updateFormData = async (data: FormDataParams, setValue: SetValueType) => {
  try {
    // Payer Data
    const email = data?.payer?.email_address || '';
    const firstName = data?.payer?.name?.given_name || '';
    const lastName = data?.payer?.name?.surname || '';
    const phoneNumber = data?.payer?.phone?.phone_number || '';
    setValue('shippingAddress[email]', email);
    setValue('shippingAddress[firstName]', firstName);
    setValue('shippingAddress[lastName]', lastName);
    setValue('shippingAddress[phoneNumber]', phoneNumber);

    // Shipping Address (we don't bother with billing)
    const ship_address = {
      country: data?.payer?.address?.country_code || '',
      state: data?.purchase_units[0]?.shipping?.address?.admin_area_1 || '',
      city: data?.purchase_units[0]?.shipping?.address?.admin_area_2 || '',
      street1: data?.purchase_units[0]?.shipping?.address?.address_line_1 || '',
      street2: data?.purchase_units[0]?.shipping?.address?.address_line_2 || '',
      zipcode: data?.purchase_units[0]?.shipping?.address?.postal_code || '',
    };

    setValue('shippingAddress[state]', ship_address.state);
    setValue('shippingAddress[city]', ship_address.city);
    setValue(
      'shippingAddress[address]',
      `${ship_address.street1} ${ship_address.street2}`,
    );
    setValue('shippingAddress[zipCode]', ship_address.zipcode);
    return Promise.resolve();
  } catch (e) {
    return Promise.reject();
  }
};

/**
 * Appropriately formatted payment information for what the Spree
 * endpoint expects
 *
 * @param details
 * @returns the necessary payment details for a PayPal purchase
 */
export const formatPayPalPaymentDetails = (details: { id: string; payer: { payer_id: string; }; }) => {
  return {
    payment_id: details.id,
    payer_id: details.payer.payer_id,
  };
};
