import {
  size,
  colors,
  typography,
  Col,
  mediaQueries,
  Tooltip,
} from '@everlywell/leaves';
import styled from 'styled-components';

const StripeInputWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
`;

const StripeLabel = styled.label`
  font-weight: ${typography.weight.bold};
  font-family: ${typography.type.nexa};
  font-size: ${size.md}px;
  color: ${colors.gray4};
  line-height: 1.5;
  letter-spacing: normal;
`;

const StripeInput = styled.div.attrs((props: { name?: string; }) => ({
  name: props.name,
}))`
  color: ${colors.gray4};
  font-family: ${typography.type.nexa};
  background: ${colors.white};
  border-radius: 1px;
  padding: ${size.xs1}px ${size.md}px;
  border: 1px solid ${colors.green2};
  box-shadow: 4px 4px 15px -5px rgba(0, 0, 0, 0.1);
  &.StripeElement--focus {
    border: 1px solid ${colors.gray2};
    border-bottom: 1px solid ${colors.teal5};
    box-shadow: ${colors.teal5} 0 0 0 1px inset;
    + ${StripeLabel} {
      color: ${colors.teal4};
    }
  }
  &.StripeElement--invalid {
    border: 1px solid ${colors.gray2};
    border-bottom: 1px solid ${colors.red3};
    box-shadow: ${colors.red3} 0 0 0 1px inset;
    + ${StripeLabel} {
      color: ${colors.red3};
    }
  }
`;

const FlexOrder = styled(Col).attrs((props: { mobileOrder?: string; order?: string; }) => ({
  mobileOrder: props.mobileOrder,
  order: props.order,
}))`
  order: ${(props) => props.mobileOrder};
  margin-bottom: ${size.sm}px;
  ${mediaQueries.forTabletHorizontalUp} {
    order: ${(props) => props.order};
  }
  padding: 0px;
`;

const TooltipWrapper = styled.div`
  position: absolute;
  right: 2%;
  top: 40%;
  color: ${colors.green4};
  ${mediaQueries.forTabletHorizontalDown} {
    top: 40%;
    margin-right: 10px;
  }
  ${mediaQueries.forPhoneOnly} {
    top: 40%;
    margin-right: 10px;
  }
`;

const CvcTooltip = styled(Tooltip)`
  .tooltipBox {
    width: 295px;
    height: 110px;
    padding: ${size.xs1}px ${size.sm}px;
    ${mediaQueries.forPhoneOnly} {
      width: 150px;
      height: 210px;
      padding: ${size.xs1}px ${size.sm}px;
    }
  }
`;

const StyledInputContainer = styled.div`
  position: relative;
  padding-bottom: ${size.sm}px;
  margin-bottom: ${size.lg}px;
  ${mediaQueries.forTabletHorizontalUp} {
    padding-bottom: ${size.sm}px;
    margin-bottom: 0;
  }
`;

export {
  FlexOrder,
  StripeInputWrapper,
  StripeInput,
  StripeLabel,
  CvcTooltip,
  TooltipWrapper,
  StyledInputContainer,
};
