import axios from 'axios';

/**
 *
 * @param {Object} orderNumber spree order number
 *
 */

const orderComplete = (orderNumber, payload) => {
  let jwt = window ? window.localStorage.getItem('jwt') : null;
  const paymentMethod =
    payload?.order?.payment_provider?.toLowerCase() === 'paypal'
      ? 'paypal'
      : 'stripe';
  let orderCompletionEndpoint;
  if (process.env.GATSBY_AUTOMATION) {
    orderCompletionEndpoint =
      process.env.GATSBY_TEST_TAKER_PAID_ORDER_COMPLETE_ENDPOINT;
  } else {
    orderCompletionEndpoint = `${process.env.GATSBY_TEST_TAKER_PAID_ORDER_COMPLETE_ENDPOINT}_${paymentMethod}`;
  }

  return axios.patch(
    orderCompletionEndpoint.replace(/<id>/, orderNumber),
    payload,
    {
      headers: {
        Authorization: `Basic ${jwt}`,
      },
    },
  );
};

export default orderComplete;
