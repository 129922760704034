import { useLocation } from '@reach/router';
import { AnalyticsPageMeta } from 'components/common';
import { ProgressBar } from 'components/ui';
import { navigate } from 'gatsby';
import { useFindManyProducts, useStep, usePageTitle } from 'hooks';
import React from 'react';
import { setInnerNavigate } from 'utils/services/auth';

import { PAGE_INFO } from './constants';
import InfoStep from './info-step';
import ReviewStep from './review-step';
import ShipmentStep from './shipment-step';
import { OuterWrapper, InnerWrapper } from './styles';
import TestsStep from './tests-step';

interface LocationState {
  productIds: number[];
  contentfulProductIds: number[];
  uniqueId: {
    required: boolean;
    format?: string;
    label?: string;
    tooltip?: string;
  };
}

type StepsIndex = 0 | 1 | 2 | 3;

const steps = ['info', 'tests', 'shipment', 'review'];

const Wizard = () => {
  const location = useLocation();
  const locationState = location.state as LocationState;
  const contentfulProductIds = locationState?.contentfulProductIds || [];
  const uniqueId = locationState?.uniqueId || {};
  const products = useFindManyProducts({ productIds: contentfulProductIds });

  const {
    currentStep,
    navigation: { goToNextStep, goToPreviousStep, goToStep },
    hasNextStep,
    isLastStep,
  } = useStep({ steps });

  const { title, name } = PAGE_INFO[currentStep as StepsIndex];

  const moveForward = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });

    if (hasNextStep) {
      goToNextStep();
    }

    if (isLastStep) {
      setInnerNavigate();
      navigate('/app/order-placed');
    }
  };

  usePageTitle(title);

  return (
    <OuterWrapper>
      <AnalyticsPageMeta pageName={name} />
      <InnerWrapper>
        <ProgressBar
          currentStep={currentStep}
          steps={steps}
          goToStep={goToStep}
        />

        {
          {
            0: <InfoStep moveForward={moveForward} uniqueId={uniqueId} />,
            1: (
              <TestsStep
                products={products}
                moveForward={moveForward}
              />
            ),
            2: <ShipmentStep moveForward={moveForward} />,
            3: (
              <ReviewStep
                products={products}
                moveForward={moveForward}
                goToPreviousStep={goToPreviousStep}
                currentStep={currentStep as StepsIndex}
              />
            ),
          }[currentStep]
        }
      </InnerWrapper>
    </OuterWrapper>
  );
};

export default Wizard;
