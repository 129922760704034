import { colors, H3, size } from '@everlywell/leaves';
import primaryShipment from 'assets/images/icons-general-primary-shipment.svg';
import styled from 'styled-components';

const Container = styled.div`
  padding: 60px;
  margin-top: 100px;
  border-radius: 1px;
  background-color: ${colors.white};
  box-shadow: 0 2.5px 10px 0 rgba(189, 196, 197, 0.5);
`;

const Title = styled(H3)`
  line-height: 1.39;
  text-align: center;
`;

const Icon = styled.div`
  width: 56px;
  height: 56px;
  margin: ${size.md}px 0;
  background: url(${primaryShipment});
`;

const OrderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Text = styled.div<{ md?: boolean }>`
  text-align: left;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.43;
  letter-spacing: 0.26px;
  color: ${colors.gray4};
  margin: ${(props) => (props.md ? size.md : size.xl1)}px 0 0;
`;

const OrderText = styled(Text)`
  margin: 0;
  font-weight: normal;
`;

const OrderNumber = styled(OrderText)`
  font-size: 20.3px;
  line-height: 1.28;
  letter-spacing: 0.3px;
  color: ${colors.gray5};
`;

const OrderTitle = styled(OrderText)`
  font-size: ${size.md}px;
  line-height: 1.75;
`;

const Email = styled.a`
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 0.26px;
  margin-bottom: 20px;
  color: ${colors.green4};
  text-decoration: none;

  &:hover {
    color: ${colors.green5};
    text-decoration: underline;
  }
`;

const SupportFormLink = Email;

export {
  Container,
  Icon,
  OrderContainer,
  OrderTitle,
  OrderNumber,
  Text,
  Title,
  Email,
  SupportFormLink,
};
