import apiQuota from 'api/order-quota';
import apiUniqueIdValidator from 'api/unique-id-validator';
import PersonalInformationForm from 'components/forms/PersonalInformationForm';
import { WizardContext } from 'contexts/wizard';
import React, { useContext } from 'react';
import { ERROR_TITLE, ERROR_CONTENT } from 'utils/constants';
import {
  analytics,
  displayError,
  formatDateOfBirth,
} from 'utils/helpers';
import { getUser } from 'utils/services/auth';

import { PAGE_INFO } from '../constants';
import * as S from './styles';

interface Props {
  uniqueId: {
    required: boolean;
    format?: string;
    label?: string;
    tooltip?: string;
  },
  moveForward: () => void;
}

interface FormData {
  firstName: string;
  lastName: string;
  dob: string;
  biologicalSex: string;
  email: string;
  thirdPartyId: string;
  confirmThirdPartyId: string;
}

interface ContextData extends FormData {
  userIdLabel?: string;
  userId: string;
  tenantName: string;
  birthday: string;
  lineItems?: LineItem[],
  contentfulProductIds?: number[],
};

type ValidateUniqueIdParams = Pick<ContextData, 'thirdPartyId' | 'dob' | 'lastName' | 'tenantName'>;

/**
 * Personal Information page
 */
const InfoStep = ({ moveForward, uniqueId }: Props) => {
  const {
    firstName,
    lastName,
    biologicalSex,
    birthday: dob,
    email,
    userId: thirdPartyId,
    userConfirmId: confirmThirdPartyId,
    setWizard,
    tenantName,
    uniqueIdValidationEnabled,
    autoRegisterOrders,
    lineItems,
    contentfulProductIds,
  } = useContext(WizardContext);

  const validateUniqueId = async (data: ValidateUniqueIdParams) => {
    try {
      const response = await apiUniqueIdValidator(
        {
          member_id: data.thirdPartyId,
          dob: formatDateOfBirth(data.dob),
          last_name: data.lastName,
        },
        data.tenantName,
      );

      return response.data;
    } catch (error) {
      return { statusCode: '500', error };
    }
  };

  const onSubmit = async (formData: FormData, setLoading: React.Dispatch<React.SetStateAction<boolean>>) => {
    const eventData = {
      page_name: PAGE_INFO[0].name,
    };

    let contextData: ContextData = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      birthday: formData.dob,
      dob: formData.dob,
      biologicalSex: formData.biologicalSex,
      email: formData.email,
      thirdPartyId: formData.thirdPartyId,
      confirmThirdPartyId: formData.confirmThirdPartyId,
      userIdLabel: uniqueId.label,
      tenantName: tenantName,
      userId: formData.thirdPartyId
    };

    if (uniqueId.required && uniqueIdValidationEnabled) {
      const response = await validateUniqueId(contextData);

      if (response.statusCode !== '200') {
        setLoading(false);
        let compiledEventData = { ...eventData, ...response.data?.validated_attrs };
        
        analytics.track('CTA Clicked', {
          ...compiledEventData,
          error_type: ERROR_TITLE.INVALID_UNIQUE_ID,
          text: ERROR_CONTENT.INVALID_UNIQUE_ID.replace(/(<([^>]+)>)/gi, ''),
        });

        return displayError(
          ERROR_TITLE.INVALID_UNIQUE_ID,
          ERROR_CONTENT.INVALID_UNIQUE_ID,
        );
      }

      // Filtering down the list due to some misconfigured access codes for which we needed to
      // narrow down the list of products to only what the member is eligible for in 3rd Party Member ID
      // Related ticket: https://everlyhealth.atlassian.net/browse/BUG-72
      if (process.env.GATSBY_ACCESS_CODE_ELIGIBLITY_CHECK_ENABLED === 'true') {
        const memberProductIds: number[] = response?.data?.kits?.map(
          (kit: Kit) => kit.ew_product_id,
        );
        const newLineItems = lineItems.filter((lI) =>
          memberProductIds.includes(lI.product_id),
        );
        const newContentfulProductIds = contentfulProductIds.filter((cP) =>
          memberProductIds.includes(cP),
        );

        contextData = {
          ...contextData,
          lineItems: newLineItems,
          contentfulProductIds: newContentfulProductIds,
        };

        setWizard({
          ...contextData,
        });
      }
    }

    apiQuota({
      email: formData.email,
      uniqueId: formData.thirdPartyId,
      accessCode: getUser().accessCode,
      enterprisePartnerId: process.env.GATSBY_ENTERPRISE_PARTNER_ID,
    })
      .then((response) => {
        const data = response.data;
        const { kitSelectionEnabled, allowedQuantityPerProduct } = data;

        analytics.track('CTA Clicked', {
          ...eventData,
          text: 'Verified Personal Information Successfully',
        });

        setWizard({
          kitSelectionEnabled: kitSelectionEnabled,
          allowedQuantityPerProduct: kitSelectionEnabled
            ? allowedQuantityPerProduct
            : null,
          isQuotaFull: false,
          ...contextData,
        });
      })
      .catch(() => {
        setWizard({ isQuotaFull: true, ...contextData });
      })
      .finally(() => {
        moveForward();
      });
  };

  return (
    <S.Container>
      <S.Title>Personal Information</S.Title>
      <S.Description>
        Enter your information to order your Everlywell test kit.
      </S.Description>

      <PersonalInformationForm
        onSubmit={onSubmit}
        email={email}
        dob={dob}
        firstName={firstName}
        lastName={lastName}
        biologicalSex={biologicalSex}
        uniqueId={uniqueId}
        uniqueIdTooltip={uniqueId?.tooltip}
        thirdPartyId={thirdPartyId}
        confirmThirdPartyId={confirmThirdPartyId}
        liteDisplay={true}
        displayBiologicalSex={autoRegisterOrders && uniqueIdValidationEnabled}
      />
    </S.Container>
  );
};

export default InfoStep;
