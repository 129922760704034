import axios from 'axios';

const ENDPOINT = process.env.GATSBY_HEALTH_PLAN_INFORMATION_ENDPOINT;

/**
 *
 * @param {Object} params parameters for the unique id validator
 *  {
      "member_id": "CYPRESS1638811607652",
      "dob": "1900-01-01",
      "last_name": "unique",
      "ew_product_id": 1
    }
 *
 * @param {string} tenant tenant name for the unique id validator
 *
*/

const healthPlanInformation = (params, tenant) => {
  return axios.get(ENDPOINT, { params, headers: { tenant } });
};

export default healthPlanInformation;