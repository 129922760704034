import React, { useState } from 'react';

// local imports
import { Row, Col } from '@everlywell/leaves';
import TestTakerAddressForm from 'components/forms/TestTakerAddressForm';
import * as S from './styles';

const billingOptions = [
  {
    id: 'shipping_address',
    name: 'shipping_address',
    label: 'Same as Shipping Address',
  },
  {
    id: 'billing_address',
    name: 'billing_address',
    label: 'Use a different Billing Address',
  },
];

interface Props {
  firstName?: string;
  lastName?: string;
  address?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  phoneNumber?: string;
  errors: any;
  validations?: any;
  register: Function;
  setValue: Function;
}

export const BillingAddressForm = ({
  firstName,
  lastName,
  address,
  city,
  state,
  zipCode,
  phoneNumber,
  validations,
  errors,
  register,
  setValue,
}: Props) => {
  const [selected, setSelected] = useState('shipping_address');
  const handleAddressChange = (e: React.FormEvent<HTMLInputElement>) => setSelected(e.currentTarget.id);
  const handleAddressClick = (id: string) => setSelected(id);

  return (
    <S.BillingAddress checked={selected === 'billing_address'} sm md>
      <Row>
        <Col>
          <S.Label>Billing Address</S.Label>
        </Col>
      </Row>
      <Row>
        {billingOptions.map((option) => (
          <Col sm={12} md={6} key={option.id}>
            <S.BillingOption
              checked={selected === option.id}
              onClick={() => handleAddressClick(option.id)}
            >
              <S.StyledRadioButton
                id={option.id}
                name={option.name}
                label={option.label}
                checked={selected === option.id}
                onChange={handleAddressChange}
              />
            </S.BillingOption>
          </Col>
        ))}
      </Row>
      {selected === 'billing_address' && (
        <Row>
          <S.ShippingWrapper>
            <Col>
              <TestTakerAddressForm
                errors={errors}
                register={register}
                setValue={setValue}
                validations={validations}
                city={city}
                zipCode={zipCode}
                address={address}
                firstName={firstName}
                lastName={lastName}
                state={state}
                phoneNumber={phoneNumber}
                inputPrefix="billingAddress"
              />
            </Col>
          </S.ShippingWrapper>
        </Row>
      )}
    </S.BillingAddress>
  );
};

export default BillingAddressForm;
