import styled from 'styled-components';
import {
  H2,
  Button,
  Row,
  Container,
  colors,
  size,
  mediaQueries
} from '@everlywell/leaves';

const FormContainer = styled.form`
  width: 100%;
`;

interface ButtonGroupContainerProps {
  readonly liteDisplay: boolean;
}

const ButtonGroupContainer = styled(Row) <ButtonGroupContainerProps>`
  ${(props) =>
    props.liteDisplay
      ? `margin: ${size.xl2}px 0`
      : `margin: ${size.xl3}px 0 0 0;

      ${mediaQueries.forPhoneOnly} {
        margin-top: ${size.lg}px;
      }`}
`;

const PrimaryButton = styled(Button)`
  width: 150px;
  height: ${size.xl3}px;
  white-space: nowrap;

  ${mediaQueries.forPhoneOnly} {
    width: 100%;
  }
`;

const SecondaryButton = styled(Button)`
  width: 150px;
  height: ${size.xl3}px;
  margin-right: 10px;
  white-space: nowrap;

  ${mediaQueries.forPhoneOnly} {
    width: 100%;
    margin-right: 0;
    margin-top: ${size.md}px;
  }
`;

/********************************
 * Stories styles
 ********************************/

const TakerPaidContainer = styled.div`
  margin: ${size.sm}px 0;
  border-radius: 1px;
  background-color: ${colors.white};
  padding: 55px ${size.xl4}px 55px 63px;
  box-shadow: 0 2.5px 10px 0 rgba(189 196 197 / 50%);

  ${mediaQueries.forPhoneOnly} {
    padding: 45px ${size.lg}px ${size.lg}px;
  }
`;

const EnteprisePaidContainer = styled(Container)`
  padding: 1.5rem;
  border-radius: 1px;
  max-width: 648px;
  background-color: ${colors.white};
  box-shadow: 0 2.5px 10px 0 rgba(189 196 197 / 50%);

  ${mediaQueries.forTabletHorizontalUp} {
    padding: 1.5rem 2.5rem;
  }
`;

const EnteprisePaidTitle = styled(H2)`
  text-align: center;
  margin-bottom: 0;
`;

const EnteprisePaidDesc = styled.div`
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.75rem;
  letter-spacing: 0;
  text-align: center;
  color: ${colors.gray4};
  margin-bottom: 3rem;
`;

export {
  ButtonGroupContainer,
  FormContainer,
  PrimaryButton,
  SecondaryButton,
  TakerPaidContainer,
  EnteprisePaidContainer,
  EnteprisePaidTitle,
  EnteprisePaidDesc,
};
