import { Localize, LocalizeDisabled } from '@everlywell/leaves';
import { useLocation } from '@reach/router';
import { SEO } from 'components/common';
import React from 'react';
// local imports
import { HELP_FORM_ADDRESS } from 'utils/constants';

import TestTakerPaymentWrapper from './../index';
import * as S from './styles';
import { string } from 'prop-types';

const Summary = ({ orderId }: { orderId: string; }) => {
  return (
    <S.OrderContainer>
      <S.Icon />
      <S.OrderTitle>Order number:</S.OrderTitle>
      <S.OrderNumber>
        <LocalizeDisabled>{orderId}</LocalizeDisabled>
      </S.OrderNumber>
    </S.OrderContainer>
  );
};

const Success = () => {
  const location = useLocation();
  const locationState = location.state as { email: string; spreeOrder: { number: string }; }

  const email = locationState?.email;
  const orderId = locationState?.spreeOrder?.number.substring(0, 10);

  return (
    <S.Container>
      <span
        data-track-custom-data={JSON.stringify({
          title: 'Order Placed',
        })}
        hidden
      />
      <S.Title>Order has been placed</S.Title>

      {orderId && <Summary orderId={orderId} />}

      {email && (
        <>
          <S.Text>
            Your order is now processing. A copy of your order details will be
            sent to <Localize name="email">{email}</Localize>.
          </S.Text>
          <S.Text md={true}>
            If you have any questions about tracking your shipment, or would
            like to update your preferred shipping address, please contact the
            Everlywell Customer Care team by submitting the request form on{' '}
            <Localize name="support-form-link">
              <S.SupportFormLink href={`https://${HELP_FORM_ADDRESS}`} target="_blank">
                {HELP_FORM_ADDRESS}
              </S.SupportFormLink>
            </Localize>
            .
          </S.Text>
        </>
      )}
    </S.Container>
  );
};

const SuccessWrapper = () => {
  return (
    <TestTakerPaymentWrapper>
      <Success />
    </TestTakerPaymentWrapper>
  );
};

export default SuccessWrapper;
